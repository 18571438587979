<template>
  <div>
    <ApplicationDetails
      :appId="$route.params.appId"
      :key="$route.params.appId"
    ></ApplicationDetails>
  </div>
</template>

<script>
import ApplicationDetails from "@/components/Applications/ApplicationDetails";

export default {
  components: {
    ApplicationDetails,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
