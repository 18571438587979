<template>
  <v-menu
    bottom
    min-width="400"
    rounded
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-scale-transition>
        <v-btn
          dark
          :fab="!errorJobExist"
          :color="iconColor"
          elevation="0"
          v-on="on"
          @click="key++"
          :small="!errorJobExist"
          :height="errorJobExist ? 40 : ''"
          rounded
          class="mx-1"
        >
          <span v-if="errorJobExist" class="mr-1">{{
            numberOfErrorJobs +
            " Error" +
            (numberOfErrorJobs > 1 ? "s" : "") +
            "!"
          }}</span>
          <v-icon v-if="!errorJobExist && !runningJobExists" color="white">{{
            icon
          }}</v-icon>
          <v-progress-circular
            v-if="runningJobExists"
            size="22"
            color="white"
            width="2"
            indeterminate
            value="100"
          >
          </v-progress-circular>
        </v-btn>
      </v-scale-transition>
    </template>
    <v-card class="px-2">
      <v-card-title class="font-weight-light grey--text"
        >Notifications
        <v-spacer></v-spacer>
        <v-tooltip left v-if="jobListLength">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              text
              @click="dismissNotifications()"
              ><v-icon color="grey">mdi-notification-clear-all</v-icon></v-btn
            >
          </template>
          <span>Dismiss Notifications</span>
        </v-tooltip>
      </v-card-title>
      <v-layout column style="height: 60vh">
        <v-flex align-start style="overflow-y: auto; overflow-x: hidden">
          <v-slide-x-transition>
            <v-card-text v-show="!jobListLength" class="text-center mt-12 px-12"
              ><v-icon color="grey lighten-1" size="120">mdi-bell</v-icon>
              <p class="mt-4 mb-0 text-overline">
                Nothing to show yet. Come back here after you started an action.
              </p>
            </v-card-text>
          </v-slide-x-transition>
          <v-list two-line :key="key">
            <v-list-item-group>
              <template v-for="(job, index) in jobList">
                <v-slide-x-reverse-transition :key="job.id">
                  <v-list-item :to="/job/ + job.id">
                    <template>
                      <v-list-item-icon class="my-auto text-center">
                        <!--<v-progress-circular
                          class="ml-0"
                          size="22"
                          color="primary"
                          width="2"
                          v-if="job.status == 'running'"
                          indeterminate
                        ></v-progress-circular>-->
                        <v-icon
                          size="25"
                          v-if="job.status == 'running'"
                          color="orange darken-1"
                        >
                          mdi-progress-clock
                        </v-icon>
                        <v-icon
                          size="25"
                          v-if="job.status == 'finished' && job.errorCount == 0"
                          color="success"
                        >
                          mdi-check-circle
                        </v-icon>
                        <v-icon
                          size="25"
                          v-if="job.status == 'finished' && job.errorCount != 0"
                          color="error"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          v-text="job.name"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <span
                            v-if="job.errorCount > 0"
                            class="mt-2 error--text"
                          >
                            {{
                              `${job.errorCount} Error${
                                job.errorCount == 1 ? "" : "s"
                              }`
                            }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>{{
                          getTimespan(job.time)
                        }}</v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-slide-x-reverse-transition>
                <v-divider
                  v-if="index < jobListLength - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-flex>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      key: 0,
    };
  },
  methods: {
    getTimespan(eventTime) {
      let diff = new Date() - eventTime;
      let seconds = diff / 1000;
      if (seconds < 60) return Math.round(seconds, 0) + "s";
      let minutes = seconds / 60;
      if (minutes < 60) return Math.round(minutes, 0) + "m";
      let hours = minutes / 60;
      if (hours < 24) return Math.round(hours, 0) + "h";
      let days = hours / 24;
      return Math.round(days, 0) + "d";
    },
    statusColor(job) {
      if (job.status == "running") return "primary";
      else if (job.status == "finished") {
        if (job.errorCount === 0) return "success";
        else return "error";
      }
      return "primary";
    },
    dismissNotifications() {
      this.$store.dispatch("removeFinishedJobs");
    },
  },
  computed: {
    jobList() {
      return this.$store.state.jobList;
    },
    jobListLength() {
      return this.jobList.length;
    },
    numberOfErrorJobs() {
      return this.jobList.filter(
        (job) => job.status == "finished" && job.errorCount != 0
      ).length;
    },
    errorJobExist() {
      return this.numberOfErrorJobs > 0;
    },
    runningJobExists() {
      return this.jobList.filter((job) => job.status == "running").length > 0;
    },
    iconColor() {
      if (this.errorJobExist) return "error darken-1";
      else if (this.runningJobExists) return "orange";
      else if (this.jobListLength && !this.errorJobExist) return "success";
      return "grey darken-1";
    },
    icon() {
      if (this.jobListLength && !this.runningJobExists && !this.errorJobExist)
        return "mdi-bell-check";
      else if (this.errorJobExist) return "mdi-alert-octagon";
      return "mdi-bell";
    },
  },
};
</script>
