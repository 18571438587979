<template>
  <div class="pa-1">
    <v-row no-gutters class="py-3" justify="space-between">
      <v-col>
        <v-btn text class="font-weight-light text-capitalize">
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Add Devices</span>
        </v-btn>
        <v-btn text class="font-weight-light text-capitalize">
          <v-icon size="25" color="primary" class="mr-1">mdi-minus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Remove Devices</span>
        </v-btn>
        <v-btn
          text
          class="font-weight-light text-capitalize"
          @click="getManagedDevices()"
          :disabled="isLoading"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
        </v-btn>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" class="pr-3">
        <v-chip class="pa-5 border" color="primary" outlined
          ><v-icon class="mr-2">mdi-laptop</v-icon>
          {{
            totalDevices + " " + (totalDevices == 1 ? "Device" : "Devices")
          }}</v-chip
        >
      </v-col>
      <v-col cols="3" md="3" xl="2">
        <v-responsive width="auto" min-width="120">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            hide-details
            outlined
            rounded
          ></v-text-field>
        </v-responsive>
      </v-col>
    </v-row>

    <v-layout column style="height: calc(100vh - 190px)">
      <v-flex style="overflow: auto">
        <v-data-table
          :items="devices"
          :headers="headers"
          :items-per-page="pageSize"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100, 200],
            itemsPerPageText: 'Devices per Page',
          }"
          :server-items-length="totalDevices"
          :loading="isLoading"
          :options.sync="options"
          sort-by="deviceName"
          show-select
          dense
        >
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import graph from "@/Services/graph";

export default {
  data() {
    return {
      devices: [],
      headers: [
        { text: "Name", value: "deviceName", sortable: false },
        { text: "Compliance", value: "complianceState" },
        { text: "Last Sync Time", value: "lastSyncDateTime" },
        { text: "Enrollment Date", value: "enrolledDateTime" },
        { text: "OS Version", value: "osVersion" },
        { text: "Primary User", value: "userPrincipalName" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Model", value: "model" },
      ],
      totalDevices: 0,
      pageSize: 25,
      isLoading: false,
      options: {},
      search: null,
      message: null,
      searchTimer: null,
    };
  },
  methods: {
    getManagedDevices(byEnter = true) {
      let timeout = 1200;
      clearTimeout(this.searchTimer);
      if (this.search && this.search.length < 3) {
        this.message = "Enter at least 3 characters";
      } else {
        if (byEnter) timeout = 10;
        this.message = "";
        this.searchTimer = setTimeout(async () => {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options;
          this.isLoading = true;
          await graph
            .getManagedDevices(
              sortBy,
              sortDesc,
              page,
              itemsPerPage,
              this.search
            )
            .then((response) => {
              this.devices = response.data.value;
              this.totalDevices = response.count["@odata.count"];
            })
            .catch((err) => console.warn(err))
            .finally(() => {
              this.isLoading = false;
            });
        }, timeout);
      }
    },
  },
  watch: {
    search() {
      this.getManagedDevices(false);
    },
    options: {
      handler() {
        this.getManagedDevices();
      },
      deep: true,
    },
  },
  mounted() {
    this.getManagedDevices();
  },
};
</script>

<style lang="scss" scoped></style>
