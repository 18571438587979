import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Applications from "@/views/Applications.vue";
import ConfigurationProfiles from "@/views/ConfigurationProfiles.vue";
import Roles from "@/views/Roles.vue";
import JobDetails from "@/views/JobDetails.vue";
import DeviceOverview from "@/views/DeviceOverview";
import DeviceDetailsView from "@/views/DeviceDetailsView";
import UserDetailsView from "@/views/UserDetailsView";
import ApplicationDetailsView from "@/views/ApplicationDetailsView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/devices",
    name: "DeviceOverview",
    component: DeviceOverview,
  },
  {
    path: "/applicationdetails/:appId",
    name: "ApplicationDetails",
    component: ApplicationDetailsView,
  },
  {
    path: "/devicedetails/:deviceId",
    name: "DeviceDetails",
    component: DeviceDetailsView,
  },
  {
    path: "/userdetails/:userId",
    name: "UserDetails",
    component: UserDetailsView,
  },
  {
    path: "/roles/:roleId",
    name: "Roles",
    component: Roles,
  },
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
  },
  {
    path: "/configurationprofiles",
    name: "ConfigurationProfiles",
    component: ConfigurationProfiles,
  },
  {
    path: "/job/:jobId",
    name: "JobDetails",
    component: JobDetails,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
