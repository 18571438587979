<template>
  <div>
    <v-row no-gutters class="py-3" justify="space-between">
      <v-col>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'addConfigurationProfileToRole',
              group.type,
              permission
            )
          "
          text
          class="font-weight-light text-capitalize"
          @click="showAddConfigurationProfileDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>

          <span v-if="$vuetify.breakpoint.lgAndUp">Add Profiles</span>
        </v-btn>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'removeConfigurationProfileFromRole',
              group.type,
              permission
            )
          "
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showRemoveConfigurationProfileDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-minus</v-icon>

          <span v-if="$vuetify.breakpoint.lgAndUp">Remove Profiles</span>
        </v-btn>
        <v-btn
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showCopyConfigurationProfileDialog = true"
        >
          <v-icon size="20" color="primary" class="mr-1"
            >mdi-content-copy</v-icon
          >

          <span v-if="$vuetify.breakpoint.lgAndUp">Copy Profiles</span>
        </v-btn>
        <v-btn
          text
          class="font-weight-light text-capitalize"
          @click="getConfigurationProfiles()"
          :disabled="isLoading"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
        </v-btn>
      </v-col>
      <v-col cols="3" md="3" xl="2">
        <v-responsive width="auto" min-width="120">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            hide-details
            outlined
            rounded
          ></v-text-field>
        </v-responsive>
      </v-col>
    </v-row>

    <v-layout column style="height: calc(100vh - 190px)">
      <v-flex style="overflow: auto">
        <v-data-table
          v-model="selectedItems"
          @toggle-select-all="selectAllToggle"
          :items="items"
          :headers="headers"
          dense
          show-select
          :loading="isLoading"
          loader-height="1"
          :items-per-page="-1"
          :search="search"
          group-by="category"
          no-data-text="No Configuration Profiles assigned"
          loading-text="Loading Configuration Profiles"
        >
          <template v-slot:group.header="props">
            <td colspan="4" class="pointer pl-4 py-0" @click="props.toggle()">
              <v-icon>{{ props.isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
              <span class="ml-7 subtitle-2">{{ props.group }}</span>
            </td>
          </template>
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
              v-if="item.membership === 'Direct'"
            ></v-simple-checkbox>
            <v-icon v-else color="grey lighten-2"
              >mdi-checkbox-blank-off-outline</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      v-model="showAddConfigurationProfileDialog"
      absolute
      right
      width="600"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <AddConfigurationProfiles
        v-if="showAddConfigurationProfileDialog"
        :group="group"
        :selectedRoles="$store.getters.selectedRoles($route.name)"
        v-on:close="
          showAddConfigurationProfileDialog = false;
          getConfigurationProfiles();
        "
      ></AddConfigurationProfiles>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showRemoveConfigurationProfileDialog"
      absolute
      right
      width="600"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <RemoveConfigurationProfiles
        v-if="showRemoveConfigurationProfileDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="
          showRemoveConfigurationProfileDialog = false;
          getConfigurationProfiles();
        "
      ></RemoveConfigurationProfiles>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showCopyConfigurationProfileDialog"
      absolute
      right
      width="600"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <CopyConfigurationProfiles
        v-if="showCopyConfigurationProfileDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="showRemoveConfigurationProfileDialog = false"
      ></CopyConfigurationProfiles>
    </v-navigation-drawer>
  </div>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import AddConfigurationProfiles from "@/components/ConfigurationProfiles/AddConfigurationProfiles";
import RemoveConfigurationProfiles from "@/components/ConfigurationProfiles/RemoveConfigurationProfiles";
import CopyConfigurationProfiles from "@/components/ConfigurationProfiles/CopyConfigurationProfiles";

export default {
  components: {
    AddConfigurationProfiles,
    RemoveConfigurationProfiles,
    CopyConfigurationProfiles,
  },
  props: {
    group: {
      type: Object,
    },
    transitive: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
      default: "None",
    },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "Name", value: "shortName" },
        { text: "Scope", value: "scope" },
        { text: "Membership", value: "membership" },
      ],
      isLoading: false,
      search: null,
      selectedItems: [],
      showAddConfigurationProfileDialog: false,
      showRemoveConfigurationProfileDialog: false,
      showCopyConfigurationProfileDialog: false,
    };
  },
  methods: {
    async getConfigurationProfiles() {
      if (!this.group.id) return;
      this.items = [];
      this.isLoading = true;
      const cfgScope = "MCM_CFG_";
      await mcmapi
        .GetMemberOf({ id: this.group.id })
        .then((response) => {
          this.items = response
            .filter(
              (x) => x.type == "group" && x.displayName.startsWith(cfgScope)
            )
            .map((obj) => {
              return {
                ...obj,
                shortName: obj.displayName.replace(cfgScope, ""),
                category: obj.displayName.split("_")[3],
                scope: obj.displayName.split("_")[2],
                membership: "Direct",
              };
            });
          this.selectedItems = [];
        })
        .catch((err) => console.warn(err));

      await mcmapi
        .GetMemberOf({ id: this.group.id, transitive: true })
        .then((response) => {
          this.items = this.items.concat(
            response
              .filter(
                (x) =>
                  x.type == "group" &&
                  x.displayName.startsWith(cfgScope) &&
                  !this.items.some((y) => y.id === x.id)
              )
              .map((obj) => {
                return {
                  ...obj,
                  shortName: obj.displayName.replace(cfgScope, ""),
                  category: obj.displayName.split("_")[3],
                  scope: obj.displayName.split("_")[2],
                  membership: "Inherited",
                };
              })
          );
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectAllToggle(props) {
      let availableItems = props.items.filter(
        (item) => item.membership === "Direct"
      );
      if (this.selectedItems.length !== availableItems.length) {
        this.selectedItems = availableItems;
      } else {
        this.selectedItems = [];
      }
    },
  },
  computed: {
    ConfigurationProfileCount() {
      return this.items.length;
    },
  },
  mounted() {
    if (this.group) this.getConfigurationProfiles();
  },
};
</script>

<style></style>
