<template>
  <v-card elevation="0">
    <v-card-text class="pa-1">
      <v-row no-gutters class="py-3" justify="space-between">
        <v-col class="mb-0">
          <v-btn
            text
            class="font-weight-light text-capitalize"
            :disabled="
              !selectedItems.length ||
              !$store.getters.selectedRoles($route.name).length
            "
            @click="showAddConfigurationProfilesDialog = true"
          >
            <v-icon size="25" color="primary" class="mr-1"
              >mdi-application-export</v-icon
            >
            <span v-if="$vuetify.breakpoint.lgAndUp">Add to Roles</span>
          </v-btn>
          <v-btn
            text
            class="font-weight-light text-capitalize"
            @click="getConfigurationProfiles()"
            :disabled="isLoading"
          >
            <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
          </v-btn>
          <v-btn
            text
            class="font-weight-light text-capitalize"
            href="https://endpoint.microsoft.com/#blade/Microsoft_Intune_DeviceSettings/DevicesWindowsMenu/configProfiles"
            target="New"
          >
            <v-icon size="25" color="primary" class="mr-1"
              >mdi-microsoft-azure</v-icon
            >
            <span v-if="$vuetify.breakpoint.lgAndUp">Open in Azure</span>
          </v-btn>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" class="pr-3">
          <v-chip class="pa-5 border" color="primary" outlined
            ><v-icon class="mr-2">mdi-cog-box</v-icon>
            {{
              profileCount + " " + (profileCount == 1 ? "Profile" : "Profiles")
            }}</v-chip
          >
        </v-col>
        <v-col cols="12" md="4" xl="2" class="mb-0">
          <v-responsive width="auto" min-width="120">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
              outlined
              rounded
            ></v-text-field>
          </v-responsive>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <div v-if="treeMode === 'Users'">
            <v-switch
              color="primary"
              v-model="showDeviceConfigurationProfiles"
              dense
              hide-details
              class="mt-0 mb-2 ml-5"
              inset
            >
              <template v-slot:label>
                <span class="font-weight-medium">Show Device CFGs</span>
                <v-tooltip
                  top
                  v-if="showDeviceConfigurationProfiles"
                  max-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      color="error darken-1"
                      dark
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Assigning Device Configuration Profiles to users only works
                    if the users are the primary user of a device! (User centric
                    management)</span
                  >
                </v-tooltip>
              </template></v-switch
            >
          </div>
        </v-col>
      </v-row>

      <v-layout column style="height: calc(100vh - 155px)">
        <v-flex style="overflow-y: auto; overflow-x: hidden">
          <v-data-table
            v-model="selectedItems"
            :items="configurationProfilesToDisplay"
            :headers="headers"
            dense
            show-select
            :loading="isLoading"
            loader-height="1"
            :items-per-page="-1"
            :search="search"
            group-by="category"
            sort-by="shortName"
            loading-text="Loading Configuration Profiles"
          >
            <template v-slot:group.header="props">
              <td colspan="2" class="pointer pl-4 py-1" @click="props.toggle()">
                <v-icon>{{ props.isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
                <span class="ml-7 subtitle-2">{{ props.group }}</span>
              </td>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-navigation-drawer
        v-model="showAddConfigurationProfilesDialog"
        absolute
        right
        width="450"
        temporary
        :overlay-opacity="0"
        height="100%"
      >
        <AddConfigurationProfiles
          v-if="showAddConfigurationProfilesDialog"
          :itemsArePopulatedFromParent="true"
          :itemsFromParent="selectedItems"
          :selectedRoles="$store.getters.selectedRoles($route.name)"
          v-on:close="showAddConfigurationProfilesDialog = false"
        ></AddConfigurationProfiles>
      </v-navigation-drawer>
    </v-card-text>
  </v-card>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import AddConfigurationProfiles from "@/components/ConfigurationProfiles/AddConfigurationProfiles";

export default {
  components: {
    AddConfigurationProfiles,
  },
  data() {
    return {
      items: [],
      selectedItems: [],
      headers: [{ text: "Name", value: "shortName" }],
      isLoading: false,
      search: null,
      showAddConfigurationProfilesDialog: false,
      showDeviceConfigurationProfiles: false,
    };
  },
  methods: {
    async getConfigurationProfiles() {
      this.items = [];
      this.isLoading = true;
      await mcmapi
        .GetConfigurationProfiles()
        .then((response) => {
          this.items = response;
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    profileCount() {
      return this.configurationProfilesToDisplay.length;
    },
    treeMode() {
      return this.$store.state.treeMode;
    },
    configurationProfilesToDisplay() {
      const cfgScope = this.showDeviceConfigurationProfiles
        ? "MCM_CFG_Devices_"
        : "MCM_CFG_" + this.treeMode + "_";
      return this.items
        .filter((obj) => {
          return obj.displayName.startsWith(cfgScope);
        })
        .map((obj) => {
          return {
            ...obj,
            shortName: obj.displayName.replace(cfgScope, ""),
            category: obj.displayName.split("_")[3],
          };
        });
    },
  },
  watch: {
    treeMode() {
      this.selectedItems = [];
    },
  },
  mounted() {
    this.getConfigurationProfiles();
  },
};
</script>

<style></style>
