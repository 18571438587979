<template>
  <TSidePanel show-search :search.sync="search" v-on:close="$emit('close')">
    <template v-slot:title> Add Other Items </template>

    <template v-slot:top-title> Available Items</template>

    <template v-slot:top-content>
      <v-data-table
        v-model="selectedItems"
        :items="items"
        :headers="headers"
        dense
        show-select
        :loading="isLoading"
        loader-height="1"
        :items-per-page="-1"
        :search="search"
        group-by="category"
        sort-by="shortName"
        loading-text="Loading"
      >
        <template v-slot:group.header="props">
          <td colspan="2" class="pointer pl-4 py-1" @click="props.toggle()">
            <v-icon>{{ props.isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
            <span class="ml-7 subtitle-2">{{ props.group }}</span>
          </td>
        </template>
      </v-data-table>
    </template>

    <template v-slot:bottom-title>
      Selected Items ({{ selectedItems.length }})
    </template>

    <template v-slot:bottom-content>
      <v-list
        v-if="selectedItems.length"
        color="transparent"
        dense
        class="pt-0"
      >
        <v-list-item v-for="cp in selectedItems" :key="cp.id" class="pl-0 ma-0">
          <v-list-item-icon
            ><v-icon>mdi-sticker-plus-outline</v-icon></v-list-item-icon
          >
          <span>
            {{ cp.shortName }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!selectedItems.length"
        @click="addItems"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add Items
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
    selectedRoles: {
      type: Array,
      default: function () {
        return [];
      },
    },
    assignmentGroupTypes: {
      type: Array,
    },
  },
  data() {
    return {
      items: [],
      headers: [{ text: "Name", value: "shortName" }],
      selectedItems: [],
      isLoading: false,
      jobCreated: false,
      search: null,
    };
  },
  methods: {
    async getAvailableItems(tag, category) {
      this.isLoading = true;
      const startString = `MCM_${tag}_`;
      await mcmapi
        .GetAssignmentGroups(tag.toLowerCase())
        .then((response) => {
          this.items = this.items.concat(
            response.map((obj) => {
              return {
                ...obj,
                shortName: obj.displayName.replace(startString, ""),
                category: category,
              };
            })
          );
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
    closeDialog() {
      this.$emit("close");
    },
    addItems() {
      this.$store.dispatch("addRolesToAssignmentGroups", {
        groups: this.selectedItems,
        roles: this.selectedRoles,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
    refresh() {
      this.assignmentGroupTypes.forEach((x) =>
        this.getAvailableItems(x.tag, x.name)
      );
    },
  },
  computed: {
    treeMode() {
      return this.$store.state.treeMode;
    },
  },
  watch: {
    itemsFromParent(newVal) {
      this.selectedItems = newVal;
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped></style>
