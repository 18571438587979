<template>
  <TSidePanel full-height v-on:close="$emit('close')">
    <template v-slot:title>Delete Devices from AAD</template>

    <template v-slot:top-title> Selected Devices ({{ items.length }})</template>

    <template v-slot:top-content>
      <v-list v-if="items.length" color="transparent" dense class="pt-0">
        <v-list-item v-for="device in items" :key="device.id" class="pl-0 ma-0">
          <v-list-item-icon><v-icon>mdi-laptop</v-icon></v-list-item-icon>
          <span>
            {{ device.displayName }}
          </span>
        </v-list-item>
      </v-list>
    </template>
    <!--
    <template v-slot:bottom-title> Delete from</template>

    <template v-slot:bottom-content>
      <v-select v-model="deleteFrom" :items="deleteFromOptions" :hint="deleteFrom.hint" return-object outlined>
      </v-select>
    </template>
-->
    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!items.length"
        @click="deleteDevices"
      >
        <v-icon class="mr-2">mdi-minus</v-icon>
        Delete Devices
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      jobCreated: false,
      deleteFrom: { value: 7 },
      deleteFromOptions: [
        {
          text: "Delete entirely",
          value: 7,
          hint: "Delete device from Intune, Azure AD & Autopilot. Use this when a device is taken out of service or is going to be used in another device management solution.",
        },
        {
          text: "Intune only",
          value: 1,
          hint: "Delete from Intune only. Use this option when a device is reset or reinstalled.",
        },
      ],
      deleteFromAzureAD: true,
      deleteFromAutopilot: true,
      deleteFromIntune: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    deleteDevices() {
      this.$store.dispatch("deleteDevices", {
        devices: this.items,
        group: this.group,
        deleteFrom: this.deleteFrom,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
  mounted() {
    this.deleteFrom = this.deleteFromOptions[0];
  },
};
</script>
