<template>
  <div>
    <span class="ml-12 pl-12 text-h3 font-weight-thin">News</span>
    <v-timeline class="mr-3" dense>
      <v-timeline-item
        v-for="(line, i) in news"
        :key="i"
        :color="line.color"
        small
      >
        <template v-slot:opposite>
          <span
            :class="`title font-weight-bold ${line.color}--text`"
            v-text="line.date"
          ></span>
        </template>
        <v-card flat outlined color="grey lighten-4">
          <v-card-title class="text-h6 py-2">
            {{ line.title }}
            <v-spacer></v-spacer>
            <span class="caption">{{ line.date }}</span>
          </v-card-title>
          <v-card-text>
            <span v-html="line.message"></span>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
export default {
  data: () => ({
    news: [
      {
        color: "blue",
        date: "2024/05/29",
        title: "Release 12",
        message:
          "Short news everyone, <br> we fixed view limit in device details, now you can see more groups in group membership tab (max 999) before it was 100",
      },
      {
        color: "orange",
        date: "2024/01/23",
        title: "Release 11",
        message:
          "Happy New Year 2024! <br> We fixed a bug with app assignments where we could not use special characters in application group names and one random bug with special assigned groups",
      },
      {
        color: "pink",
        date: "2023/06/16",
        title: "Release 10",
        message:
          "We proudly presents the ability to add your devices to update rings manually. You can find it in the 'Others' tab under Devices",
      },
      {
        color: "teal lighten-2",
        date: "2023/03/14",
        title: "Release 9",
        message:
          'We added the possibility to manage your devices with a "User Centric" approach. Read more about it in Intune Operations Team. The second new feature is the possibility to copy all application and configuration profile assignments from one role to another.',
      },
      {
        color: "red darken-3",
        date: "2023/01/16",
        title: "Release 8",
        message:
          "Happy New Year! We have several new features in this release! In the applications overview you now see the application objects from Intune instead of the assigned Azure AD groups. By clicking on an application you can view the details. When assigning an app you select the installation type in the pop-up. You now can assign new stuff to your roles! There is an 'Other' tab in the 'Role' view where you can assign for instance the Windows 11 upgrade. In the device details you can see the group memberships of a device.",
      },
      {
        color: "green",
        date: "2022/10/18",
        title: "Release 7",
        message:
          "Just a small update this time. In the Configuration Profile views / selections, the policies are now grouped by category. We also added some fixed regarding the handling of special characters in role and user names.",
      },
      {
        color: "orange",
        date: "2022/09/19",
        title: "Release 6",
        message:
          'Release 6 is finally here! There are some major changes in the background of the MCM App. We have implemented a "Global Search" that allows you to directly find roles, devices and users. You can find it in the upper right corner by the magnifying glass icon. We have also enhanced the device and user detail pages. You can trigger a synchronization for devices via the "Sync" button on the details page. There is also a new feature to move devices and users to a new role. This action removes the device/user from the current role and adds it to the new role.',
      },
      {
        color: "blue",
        date: "2022/07/08",
        title: "Release 5",
        message:
          "Good news everyone! The next release is available. When creating a new City, you can now select from the existing locations in SolveIT. You can also import your Autopilot devices directly in MCM! And we added some additional information to the device overview. Have fun!",
      },
      {
        color: "teal",
        date: "2022/04/28",
        title: "New Feedback Form",
        message:
          "In the user menu in the upper right corner you can find a button to send feedback, bug reports and feature requests.",
      },
      {
        color: "orange",
        date: "2022/04/27",
        title: "Release 4",
        message:
          "Release 4 is here! We added the possibility to import users from a text file.",
      },
      {
        color: "cyan",
        date: "2022/04/13",
        title: "Release 3",
        message:
          "Release 3 is out now! Now you can manage your users the same way you manage your devices. You can switch between user and device view with the buttons on the left side. Important: Existing Favorites have to be deleted and added again!",
      },
      {
        color: "green",
        date: "2022/04/11",
        title: "Join the Intune Operations Team!",
        message:
          "Join our Intune Operations Team in Microsoft Teams to get always the latest announcements and news. There you can also find documentation and help. Join now using this code: <b>big4xvx</b>",
      },
      {
        color: "pink",
        date: "2022/03/28",
        title: "Release 2",
        message:
          "Release 2 is out now! We implemented some fixes and prepared the backend for user management.",
      },
      {
        color: "blue",
        date: "2022/03/02",
        title: "Release 1",
        message: "Welcome to Modern Client Manager!",
      },
    ],
  }),
};
</script>
