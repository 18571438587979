<template>
  <v-app id="inspire">
    <v-main v-if="user && userHasPermissions">
      <!--//* WebApp Update Snackbar -->
      <v-snackbar :value="updateExists" :timeout="-1" color="primary">
        A new Version of Modern Client Manager is available!
        <v-btn text @click="refreshApp"> Update now </v-btn>
      </v-snackbar>

      <!--//* Navigation Panel -->
      <v-navigation-drawer
        v-model="menuIsOpen"
        app
        width="360"
        :mini-variant.sync="mini"
      >
        <v-list dense class="pt-0">
          <v-list-item
            dense
            class="justify-end ma-0 pa-0 pr-1"
            style="min-height: 26px"
          >
            <v-btn icon @click.stop="mini = !mini" x-small color="grey">
              <v-icon>{{
                mini ? "mdi-chevron-double-right" : "mdi-chevron-double-left"
              }}</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item class="pl-0" dense to="/">
            <v-list-item-avatar class="my-1">
              <v-icon size="32" class="ml-3" color="orange darken-2"
                >mdi-home</v-icon
              >
            </v-list-item-avatar>

            <v-list-item-title
              class="text-subtitle-1 font-weight-light text-capitalize"
              >Home</v-list-item-title
            >
          </v-list-item>
          <v-list-item class="pl-0" dense to="/applications">
            <v-list-item-avatar class="my-1">
              <v-icon size="32" class="ml-3" color="orange darken-2"
                >mdi-apps</v-icon
              >
            </v-list-item-avatar>

            <v-list-item-title
              class="text-subtitle-1 font-weight-light text-capitalize"
              >Applications</v-list-item-title
            >
          </v-list-item>

          <v-list-item class="pl-0" dense to="/configurationprofiles">
            <v-list-item-avatar class="my-1">
              <v-icon size="32" class="ml-3" color="orange darken-2"
                >mdi-cog-box</v-icon
              >
            </v-list-item-avatar>

            <v-list-item-title
              class="text-subtitle-1 font-weight-light text-capitalize"
            >
              Configuration Profiles</v-list-item-title
            >
          </v-list-item>

          <!--<v-list-item class="pl-0">
            <v-btn to="/devices" text class="font-weight-light text-capitalize">
              <v-icon size="25" color="orange darken-2" class="mr-1">mdi-laptop</v-icon>
              Device Overview
            </v-btn>
          </v-list-item>-->
        </v-list>

        <v-divider height="4" class="mb-3"></v-divider>
        <div v-show="!mini">
          <div class="d-flex justify-space-between">
            <v-btn-toggle v-model="treeMode" group dense mandatory>
              <v-btn text class="text-uppercase grey--text mt-0 caption"
                ><v-icon class="mr-2">mdi-cellphone-link</v-icon>Device
                Roles</v-btn
              >

              <v-btn text class="text-uppercase grey--text mt-0 caption"
                ><v-icon class="mr-2">mdi-account</v-icon>User Roles</v-btn
              >
            </v-btn-toggle>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" style="width: min-content">
                  <v-switch
                    v-model="showAllCountries"
                    inset
                    dense
                    hide-details
                    class="mt-0 pt-2 mr-0 pr-0"
                  ></v-switch>
                </div>
              </template>
              <span>Show All Countries</span>
            </v-tooltip>
          </div>

          <TreeView
            v-if="userHasPermissions"
            v-show="treeMode == 0"
            mcmType="MCM_Devices_CountryRole"
            :showAllCountries="showAllCountries"
          ></TreeView>
          <TreeView
            v-if="userHasPermissions"
            v-show="treeMode == 1"
            mcmType="MCM_Users_CountryRole"
            :showAllCountries="showAllCountries"
          ></TreeView>
          <div v-if="favorites.length">
            <v-divider height="4" class="mb-3"></v-divider>
            <span class="text-uppercase grey--text ml-3 caption"
              >Favorites</span
            >
            <v-list dense class="pb-0">
              <v-list-item
                v-for="favorite in favorites"
                :key="favorite.id"
                class="pl-0"
              >
                <v-btn
                  @click="openFavorite(favorite)"
                  text
                  class="font-weight-light text-capitalize"
                >
                  <v-icon size="23" color="orange darken-2" class="mr-1"
                    >mdi-star</v-icon
                  >
                  {{ favorite.name }}
                  {{ favorite.path.length == 3 ? `(${favorite.path[1]})` : "" }}
                </v-btn>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-navigation-drawer>

      <!--//* Content -->
      <v-container fluid fill-height>
        <v-layout fill-height>
          <v-flex fill-height>
            <v-row class="d-flex fill-height">
              <v-col class="ma-0 pa-0">
                <!--//* Top Toolbar -->
                <v-toolbar flat class="pl-2">
                  <v-btn
                    v-if="!menuIsOpen"
                    @click="menuIsOpen = !menuIsOpen"
                    fab
                    text
                    color="primary"
                    dark
                    elevation="0"
                    ><v-icon>mdi-menu</v-icon></v-btn
                  >
                  <!--//* Headers -->
                  <v-card-title
                    v-if="$route.name == 'Home'"
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'body-1'"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.lgAndUp"
                      large
                      color="orange darken-3"
                      class="mr-3"
                      >mdi-home</v-icon
                    >
                    Home
                  </v-card-title>
                  <v-card-title
                    v-if="$route.name == 'DeviceOverview'"
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'body-1'"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.lgAndUp"
                      large
                      color="orange darken-3"
                      class="mr-3"
                      >mdi-laptop</v-icon
                    >
                    Device Overview
                  </v-card-title>
                  <v-card-title
                    v-if="$route.name == 'Applications'"
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'body-1'"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.lgAndUp"
                      large
                      color="orange darken-3"
                      class="mr-3"
                      >mdi-apps</v-icon
                    >
                    Applications
                  </v-card-title>
                  <v-card-title
                    v-if="$route.name == 'ConfigurationProfiles'"
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'body-1'"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.lgAndUp"
                      large
                      color="orange darken-3"
                      class="mr-3"
                      >mdi-cog-box</v-icon
                    >
                    Configuration Profiles
                  </v-card-title>

                  <v-card-title
                    v-if="$route.name == 'Roles' && currentActiveGroup"
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'body-1'"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.lgAndUp"
                      size="30"
                      color="orange darken-3"
                      class="mr-3"
                      >{{
                        $store.getters.groupTypeIcon(currentActiveGroup.type)
                      }}</v-icon
                    >
                    <div v-if="$vuetify.breakpoint.lgAndUp">
                      <span
                        v-for="(path, i) in currentActiveGroup.path"
                        :key="i"
                      >
                        {{ path }}
                        <span
                          v-if="i + 1 < currentActiveGroup.path.length"
                          class="grey--text ml-1 mr-2"
                          >|</span
                        >
                      </span>
                    </div>
                  </v-card-title>

                  <v-card-title
                    v-if="
                      ($route.name === 'ApplicationDetails' ||
                        $route.name === 'DeviceDetails' ||
                        $route.name === 'UserDetails') &&
                      currentActiveObjectName
                    "
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'body-1'"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.lgAndUp"
                      size="30"
                      color="orange darken-3"
                      class="mr-3"
                      >{{
                        $route.name === "DeviceDetails"
                          ? "mdi-laptop"
                          : "mdi-account"
                      }}</v-icon
                    >
                    {{ currentActiveObjectName }}
                  </v-card-title>

                  <!--//* Search / JobList / User Dialog -->
                  <div class="ml-auto">
                    <GlobalSearchBox></GlobalSearchBox>
                    <JobList></JobList>
                    <UserDialog></UserDialog>
                  </div>
                </v-toolbar>
                <v-divider></v-divider>

                <!--//* Router Content -->
                <v-fade-transition mode="out-in">
                  <router-view></router-view>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>

    <!--//* Loading Splash Screen / No Permission Message -->
    <div v-else class="ma-12">
      <v-img
        src="@/assets/logo.png"
        max-width="800px"
        contain
        class="mx-auto mt-12"
        style="z-index: 99999"
      ></v-img>
      <v-card
        v-if="!user"
        class="d-flex align-center justify-center"
        color="transparent"
        flat
        tile
      >
        <v-btn @click="signIn()" text large class="pa-12">
          <v-avatar size="42" color="primary">
            <v-icon color="white"> mdi-account-arrow-right </v-icon>
          </v-avatar>
          <p class="font-weight-light text-h4 text-capitalize ml-6 mt-3">
            Sign In
          </p>
        </v-btn>
      </v-card>
      <v-card
        v-if="user && !permissionsAreLoaded"
        class="text-center pl-12 mt-n9 mx-auto"
        width="685"
        color="transparent"
        flat
        tile
      >
        <v-progress-linear
          height="2"
          class="ml-2"
          style="z-index: 0"
          indeterminate
          color="#D95B2E"
          query
          reverse
          rounded
        ></v-progress-linear>
        <v-progress-linear
          height="2"
          class="ml-2"
          indeterminate
          query
          color="#3BAD67"
          rounded
        ></v-progress-linear>
      </v-card>
      <v-card
        v-if="user && permissionsAreLoaded && !userHasPermissions"
        class="text-center pt-12"
        color="transparent"
        flat
        tile
      >
        <v-icon color="grey" size="100"> mdi-account-cancel </v-icon>

        <p class="font-weight-light text-h4 text-capitalize ml-6 mt-3">
          You do not have permissions for this App!
        </p>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import auth from "./Services/auth";
import mcmapi from "@/Services/mcm-api";
import UserDialog from "./components/UserDialog";
import JobList from "./components/JobList";
import log from "./Services/log";
import TreeView from "@/components/TreeView";
import GlobalSearchBox from "@/components/Templates/GlobalSearchBox";

export default {
  components: {
    UserDialog,
    JobList,
    TreeView,
    GlobalSearchBox,
  },
  data: () => ({
    showAllCountries: false,
    menuIsOpen: null,
    permissionsAreLoaded: false,
    mini: false,
    updateExists: false,
    registration: null,
    refreshing: false,
  }),
  methods: {
    openFavorite(favorit) {
      if (this.$route.params.roleId != favorit.id)
        this.$router.push(`/roles/${favorit.id}`);
    },
    async signIn() {
      await auth.login();
      this.$store.commit("setUser", auth.user());
    },
    async getPermissions() {
      return mcmapi
        .GetPermissions()
        .then((response) => {
          this.$store.commit("setPermission", response);
        })
        .finally(() => {
          this.permissionsAreLoaded = true;
        });
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    favorites() {
      return this.$store.getters.favorites;
    },
    currentActiveGroup() {
      return this.$store.state.currentActiveGroup[0];
    },
    currentActiveObjectName() {
      return this.$store.state.currentActiveObjectName;
    },
    userHasPermissions() {
      return Object.keys(this.$store.state.permissions).length > 0;
    },
    treeMode: {
      get() {
        return this.$store.state.treeMode == "Devices" ? 0 : 1;
      },
      set(value) {
        this.$store.commit("setTreeMode", value == 0 ? "Devices" : "Users");
      },
    },
  },
  async created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });

    log.Action("configuring azure authentication");
    await auth.configure(this.$config);

    log.Action("try restoring user");
    this.$store.commit("setUser", auth.user());
    if (!this.user) {
      log.Result("restore not possible");
      log.Action("forcing user to log in");
      await auth.login();
      this.$store.commit("setUser", auth.user());
    } else {
      log.Result("user restored");
    }
  },
  mounted() {
    this.$store.dispatch("loadFavoritesFromLocalStorage");
    this.getPermissions();
  },
  watch: {
    user: function () {
      if (this.user) {
        this.getPermissions();
      }
    },
  },
};
</script>

<style>
.border-right {
  border-right: 1px solid #cccccc;
}
.border-bottom {
  border-bottom: 1px solid #cccccc !important;
}
.v-toolbar__content {
  padding: 4px 16px 4px 0 !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #cccccc white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
  border: 3px solid white;
}

html {
  overflow-y: auto !important;
}
</style>
