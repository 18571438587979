<template>
  <v-layout
    column
    :style="`height: calc(95vh - ${232 + numberOfFavorites * 40}px)`"
  >
    <v-flex style="overflow-y: auto; overflow-x: hidden">
      <v-flex v-if="!itemsToDisplay.length" class="text-center pt-12">
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
          size="60"
          width="5"
        ></v-progress-circular>
      </v-flex>

      <v-treeview
        class="mt-2"
        :active.sync="active"
        :items="itemsToDisplay"
        :load-children="getChildren"
        :selectable="multiSelectEnabled"
        :item-disabled="multiSelectEnabled ? 'disabled' : 'none'"
        v-model="selectedRoles"
        activatable
        return-object
        hoverable
        transition
        dense
        color="orange darken-2"
        selection-type="independent"
        selected-color="orange darken-2"
      >
        <template v-slot:label="{ item }">
          <span
            class="grey--text text--darken-2 pointer font-weight-light text-capitalize"
            >{{ item.name }}
          </span>
        </template>
        <template v-slot:append="{ item, open }">
          <v-btn
            v-if="open"
            @click="getChildren(item)"
            fab
            text
            x-small
            class="ma-0 pa-0"
            color="grey darken-1"
            ><v-icon small class="ma-0">mdi-refresh</v-icon></v-btn
          >
          <v-icon v-else small class="pr-2">
            {{ $store.getters.groupTypeIcon(item.type) }}
          </v-icon>
        </template>
      </v-treeview>
    </v-flex>
  </v-layout>
</template>

<script>
import mcmapi from "@/Services/mcm-api";

const sortFunction = (a, b) => {
  let fa = a.name.toLowerCase(),
    fb = b.name.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

export default {
  props: {
    mcmType: {
      type: String,
      default: "MCM_Devices_CountryRole",
    },
    showAllCountries: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),

  methods: {
    async getCountries() {
      await mcmapi
        .GetCountries()
        .then((response) => {
          this.items = response
            .filter((x) => x.mcmType == this.mcmType)
            .sort((a, b) => sortFunction(a, b))
            .map((resp) => {
              const type = resp.mcmType.split("_")[2];
              return {
                ...resp,
                children: [],
                type: type,
                countryId: resp.id,
                disabled: !this.$store.getters.userHasPermission(
                  "addApplicationToRole",
                  type,
                  this.$store.getters.permissionForCountry(resp.id)
                ),
              };
            });
        })
        .catch((err) => console.warn(err));
    },
    async getChildren(item) {
      let method = mcmapi.GetCitiesByCountryId;
      if (item.type == "CityRole") {
        method = mcmapi.GetBusinessesByCityId;
      }
      await method(item.id).then(
        (response) =>
          (item.children = response
            .sort((a, b) => sortFunction(a, b))
            .map((resp) => {
              const type = resp.mcmType.split("_")[2];
              return {
                ...resp,
                children: type == "BusinessRole" ? null : [],
                type: type,
                countryId: item.countryId,
                disabled: !this.$store.getters.userHasPermission(
                  "addApplicationToRole",
                  type,
                  this.$store.getters.permissionForCountry(item.countryId)
                ),
              };
            }))
      );
    },
  },

  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
    active: {
      get() {
        return this.$store.state.currentActiveGroup;
      },
      set(newVal) {
        if (newVal.length === 1 && newVal[0].id != this.$route.params.roleId)
          this.$router.push(`/roles/${newVal[0].id}`);
      },
    },
    itemsToDisplay() {
      if (this.showAllCountries) {
        return this.items;
      } else {
        return this.items.filter((x) => {
          return !!this.permissions[x.id];
        });
      }
    },
    selectedRoles: {
      get() {
        if (this.mcmType == "MCM_Devices_CountryRole")
          return this.$store.state.selectedDeviceRoles;
        else if (this.mcmType == "MCM_Users_CountryRole")
          return this.$store.state.selectedUserRoles;
        return [];
      },
      set(newVal) {
        if (this.mcmType == "MCM_Devices_CountryRole")
          this.$store.commit("setSelectedDeviceRoles", newVal);
        else if (this.mcmType == "MCM_Users_CountryRole")
          this.$store.commit("setSelectedUserRoles", newVal);
      },
    },
    multiSelectEnabled() {
      return (
        this.$route.name == "Applications" ||
        this.$route.name == "ConfigurationProfiles"
      );
    },
    numberOfFavorites() {
      return this.$store.getters.favorites.length;
    },
  },
  watch: {
    $route(to, from) {
      if (from.name == "Roles") {
        this.$store.commit("setCurrentActiveGroup", []);
      }
      this.$store.commit("setCurrentActiveObjectName", null);
    },
  },
  mounted() {
    this.getCountries();
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
