<template>
  <v-container>
    <v-layout column style="height: calc(100vh - 90px)">
      <v-flex style="overflow-y: auto; overflow-x: hidden">
        <v-row>
          <v-col
            cols="8"
            lg="8"
            offset="2"
            offset-lg="2"
            md="12"
            offset-md="0"
            sm="12"
            offset-sm="0"
          >
            <Timeline></Timeline>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Timeline from "@/components/Timeline";

export default {
  components: {
    Timeline,
  },
};
</script>

<style lang="scss" scoped></style>
