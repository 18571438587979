<template>
  <div>
    <DeviceDetails
      :azureDevice="{ deviceId: $route.params.deviceId }"
      :key="$route.params.deviceId"
    ></DeviceDetails>
  </div>
</template>

<script>
import DeviceDetails from "@/components/Devices/DeviceDetails";

export default {
  components: {
    DeviceDetails,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
