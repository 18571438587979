<template>
  <v-menu
    bottom
    min-width="600"
    rounded
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" fab small color="primary" elevation="0" class="mx-1"
        ><v-icon>mdi-magnify</v-icon></v-btn
      >
    </template>

    <v-card class="px-2">
      <v-card-title class="font-weight-light grey--text"
        >Global Search
      </v-card-title>
      <v-text-field
        placeholder="Search for Roles, Devices and Users"
        class="mt-1 mx-3"
        outlined
        rounded
        v-model="search"
        hide-details=""
        @input="searchObjects()"
        clearable
        dense
      >
      </v-text-field>
      <span class="text-caption ml-7 error--text">{{ searchMessage }}</span>
      <v-layout column style="height: 60vh">
        <v-flex style="overflow-y: auto; overflow-x: hidden" class="px-3">
          <v-card-text v-if="neverSearched" class="text-center mt-12"
            ><v-icon color="grey lighten-3" size="180">mdi-cloud-search</v-icon>
          </v-card-text>
          <v-card-text
            v-if="!neverSearched && !isLoading && noResults"
            class="text-center mt-12"
            ><v-icon color="grey lighten-1" size="120"
              >mdi-emoticon-confused-outline</v-icon
            >
            <p class="mt-2 mb-0 text-overline">
              Sorry, there are no results for your search...
            </p>
          </v-card-text>

          <v-flex v-if="isLoading">
            <v-progress-linear
              color="primary"
              indeterminate
              height="2"
            ></v-progress-linear>
          </v-flex>

          <v-list v-if="!noResults" subheader dense>
            <v-subheader
              v-if="deviceRoles.length"
              class="text-uppercase text-overline"
              >Device Roles ({{ deviceRoles.length }})

              <v-divider class="ml-2"></v-divider
            ></v-subheader>

            <v-list-item
              v-for="role in deviceRoles"
              :key="role.id"
              :to="'/roles/' + role.id"
            >
              <v-list-item-avatar :size="35" color="primary" class="ma-1 mr-4">
                <v-icon dark> mdi-laptop</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="role.name"></v-list-item-title>

                <v-list-item-subtitle>{{
                  role.path.slice(0, 2).join(" | ")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-subheader
              v-if="userRoles.length"
              class="text-uppercase text-overline"
              >User Roles ({{ userRoles.length }})
              <v-divider class="ml-2"></v-divider
            ></v-subheader>

            <v-list-item
              v-for="role in userRoles"
              :key="role.id"
              :to="'/roles/' + role.id"
            >
              <v-list-item-avatar
                :size="35"
                color="secondary"
                class="ma-1 mr-4"
              >
                <v-icon dark> mdi-account-multiple </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="role.name"></v-list-item-title>

                <v-list-item-subtitle>{{
                  role.path.slice(0, 2).join(" | ")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-subheader
              v-if="devices.length"
              class="text-uppercase text-overline"
              >Devices ({{ devices.length }})<v-divider class="ml-2"></v-divider
            ></v-subheader>

            <v-list-item
              v-for="device in devices"
              :key="device.id"
              :to="'/devicedetails/' + device.azureADDeviceId"
            >
              <v-list-item-avatar
                :size="35"
                color="orange darken-3"
                class="ma-1 mr-4"
              >
                <v-icon dark> mdi-laptop </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="device.deviceName"
                ></v-list-item-title>

                <v-list-item-subtitle>{{
                  device.userPrincipalName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-subheader
              v-if="users.length"
              class="text-uppercase text-overline"
              >Users ({{ users.length }}) <v-divider class="ml-2"></v-divider
            ></v-subheader>

            <v-list-item
              v-for="user in users"
              :key="user.id"
              :to="'/userdetails/' + user.id"
            >
              <v-list-item-avatar
                :size="35"
                color="teal darken-2"
                class="ma-1 mr-4"
              >
                <v-icon dark> mdi-account </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="user.displayName"
                ></v-list-item-title>

                <v-list-item-subtitle>{{
                  user.principalName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import graph from "@/Services/graph";

export default {
  data() {
    return {
      search: null,
      searchMessage: null,
      isLoading: false,
      searchTimer: null,
      userRoles: [],
      deviceRoles: [],
      devices: [],
      users: [],
      neverSearched: true,
    };
  },
  methods: {
    searchObjects(byEnter = false) {
      let timeout = 1000;
      clearTimeout(this.searchTimer);
      if (this.search && this.search.length < 3) {
        this.searchMessage = "Enter at least 3 characters";
      } else if (this.search) {
        if (byEnter) timeout = 10;
        this.searchMessage = "";
        this.searchTimer = setTimeout(() => {
          this.neverSearched = false;
          this.searchResults = [];
          this.isLoading = true;
          const requests = [
            this.getUserRoles(),
            this.getDeviceRoles(),
            this.getDevices(),
            this.getUsers(),
          ];
          Promise.all(requests).then(() => {
            this.isLoading = false;
          });
        }, timeout);
      }
    },
    async getUserRoles() {
      this.userRoles = [];
      return await mcmapi
        .GetRoles(this.search, "Users")
        .then((response) => {
          this.userRoles = response.values.map((obj) => ({
            ...obj,
            type: obj.mcmType.split("_")[2],
            path: obj.displayName.split("_").slice(2),
          }));
          if (this.userRoles.length >= 10)
            this.searchMessage = "Showing a maximum of 10 results per category";
          return new Promise((resolve) => {
            resolve("OK");
          });
        })
        .catch((err) => console.warn(err));
    },

    async getDeviceRoles() {
      this.deviceRoles = [];
      await mcmapi
        .GetRoles(this.search, "Devices")
        .then((response) => {
          this.deviceRoles = response.values.map((obj) => ({
            ...obj,
            type: obj.mcmType.split("_")[2],
            path: obj.displayName.split("_").slice(2),
          }));
          if (this.deviceRoles.length >= 10)
            this.searchMessage = "Showing a maximum of 10 results per category";
        })
        .catch((err) => console.warn(err));
    },

    async getDevices() {
      this.devices = [];
      await graph
        .getManagedDevices("displayName", false, 1, 10, this.search)
        .then((response) => {
          this.devices = response.value;
          if (this.devices.length >= 10)
            this.searchMessage = "Showing a maximum of 10 results per category";
        })
        .catch((err) => console.warn(err));
    },
    async getUsers() {
      this.users = [];
      await mcmapi
        .GetUsers(this.search, 10)
        .then((response) => {
          this.users = response.values;
          if (this.users.length >= 10)
            this.searchMessage = "Showing a maximum of 10 results per category";
        })
        .catch((err) => console.warn(err));
    },
  },
  computed: {
    noResults() {
      return (
        !this.userRoles.length &&
        !this.deviceRoles.length &&
        !this.devices.length &&
        !this.users.length
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
