<template>
  <TSidePanel
    :showSearch="addMode === 0"
    :searchMessage="message"
    searchLabel="Search Users..."
    :search.sync="search"
    v-on:close="$emit('close')"
    :hideSearchDetails="!message"
  >
    <template v-slot:title> Add Users </template>

    <template v-slot:top-title>
      <div class="d-flex justify-space-between my-n3">
        <v-btn-toggle v-model="addMode" group color="primary" borderless dense>
          <v-btn text class="text-capitalize">Search Users</v-btn>

          <v-btn text class="text-capitalize">Import Users</v-btn>
        </v-btn-toggle>
      </div>
    </template>

    <template v-slot:top-content>
      <v-data-table
        v-if="addMode === 0"
        :items="items"
        :headers="headers"
        :show-select="!jobCreated"
        dense
        v-model="selectedItems"
        :loading="isLoading"
        :loader-height="2"
        sort-by="displayName"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:item.userPrincipalName="{ item }">
          <p class="mb-0">{{ item.userPrincipalName }}</p>
          <span class="caption">{{ item.displayName }}</span>
        </template>
      </v-data-table>
      <div v-else>
        <FreeTextBox
          v-on:validation-passed="checkUsers($event)"
          v-model="textBoxData"
          placeholder="firstname.lastname@majorel.com (one user per line)"
          :columns="textBoxColumns"
        ></FreeTextBox>
      </div>
    </template>

    <template v-slot:bottom-title>
      Selected Users ({{ selectedItems.length }})
    </template>

    <template v-slot:bottom-content>
      <v-alert
        v-if="addMode != 0 && userCheckProgress > 0"
        dense
        prominent
        :type="alertType"
        text
        outlined
        class="pa-2"
      >
        <v-list dense class="pa-0">
          <v-list-item dense>
            <v-list-item-title class="subtitle-1">
              {{ userCheckProgress }} Users checked
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="invalidUsers.length > 0" dense>
            <v-list-item-title>
              <span class="error--text">
                {{ invalidUsers.length }} User{{
                  invalidUsers.length == 1 ? "" : "s"
                }}
                not found
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(user, index) in invalidUsers" :key="index">
            <v-list-item-content class="caption">
              {{ user }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-list
        v-if="selectedItems.length"
        color="transparent"
        dense
        class="pt-0"
      >
        <v-list-item
          v-for="user in selectedItems"
          :key="user.id"
          class="pl-0 ma-0"
        >
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <span>
            {{ user.userPrincipalName }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!selectedItems.length"
        @click="addUsersToRole"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add Users
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import graph from "@/Services/graph";
import TSidePanel from "@/components/Templates/TSidePanel";
import FreeTextBox from "@/components/Templates/FreeTextBox";

export default {
  components: {
    TSidePanel,
    FreeTextBox,
  },
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      items: [],
      skipToken: null,
      totalUsers: 0,
      headers: [{ text: "Name", value: "userPrincipalName" }],
      search: null,
      selectedItems: [],
      userType: 0,
      isLoading: false,
      jobCreated: false,
      searchTimer: false,
      message: "",
      addMode: 0,
      textBoxData: null,
      userCheckProgress: 0,
      invalidUsers: [],
      textBoxColumns: [
        {
          name: "UPN",
          //prettier-ignore
          regex: /^\S+@majorel\.com$/,
          unique: true,
          mandatory: true,
        },
      ],
    };
  },
  methods: {
    getAvailableUsers(byEnter = false) {
      let timeout = 1200;
      clearTimeout(this.searchTimer);
      if (this.search && this.search.length < 3) {
        this.message = "Enter at least 3 characters";
      } else if (this.search) {
        if (byEnter) timeout = 10;
        this.message = "";
        this.searchTimer = setTimeout(async () => {
          this.items = [];
          this.isLoading = true;
          await mcmapi
            .GetUsers(this.search)
            .then((response) => {
              this.items = response.values;
              if (response.count > 100)
                this.message = "Too many results, please refine your search";
            })
            .catch((err) => console.warn(err))
            .finally(() => {
              this.isLoading = false;
            });
        }, timeout);
      }
    },
    async checkUsers(users) {
      async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
      }

      this.selectedItems = [];
      this.userCheckProgress = 0;
      this.validUsers = [];
      this.invalidUsers = [];
      await asyncForEach(users, async (user, index) => {
        this.userCheckProgress = index + 1;
        await graph
          .getUserByUPN(user.UPN)
          .then((response) => {
            if (response.value.length == 1) {
              this.selectedItems.push(response.value[0]);
            } else {
              this.invalidUsers.push(user.UPN);
            }
          })
          .catch(() => {
            this.invalidUsers.push(user.UPN);
          });
      });
      this.userCheckIsRunning = false;
    },

    closeDialog() {
      this.$emit("close");
    },
    addUsersToRole() {
      this.$store.dispatch("addUsersToBusinessRole", {
        users: this.selectedItems,
        group: this.group,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
  computed: {
    userCount() {
      return this.selectedItems.length;
    },
    alertType() {
      return this.invalidUsers.length > 0
        ? "error"
        : this.selectedItems.length == this.userCheckProgress
        ? "success"
        : "info";
    },
  },
  watch: {
    search() {
      this.getAvailableUsers();
    },
  },
};
</script>

<style lang="scss" scoped></style>
