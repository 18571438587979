<template>
  <TSidePanel full-height v-on:close="$emit('close')">
    <template v-slot:title> Create {{ roleTypeToCreate }}</template>

    <template v-slot:top-title>New {{ roleTypeToCreate }}</template>

    <template v-slot:top-content>
      <v-form ref="form" v-model="valid">
        <v-row v-if="roleTypeToCreate == 'Cities'" no-gutters>
          <v-col cols="10" class="mt-1">
            <v-combobox
              :items="availableLocations"
              v-model="roleNames"
              :filter="comboBoxFilter"
              item-text="u_worldcode"
              item-value="u_worldcode"
              label="Select or Search Location"
              :loading="isLoading"
              multiple
              outlined
              chips
              small-chips
              color="primary"
              item-color="primary"
              deletable-chips
              hide-selected
            >
              <template v-slot:item="{ item }">
                <span class="font-weight-medium"> {{ item.u_worldcode }}</span
                ><span class="mt-0 ml-2 caption">{{ item.name }}</span>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row
          v-else
          no-gutters
          v-for="(rolename, i) in roleNames"
          :key="i"
          class="mt-1"
        >
          <v-col cols="10">
            <v-text-field
              v-model="roleNames[i]"
              class="mb-2"
              outlined
              :rules="validationRules"
              required
              dense
              :label="`${i + 1}.`"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              fab
              text
              small
              color="grey darken-1"
              @click="deleteTextField(i)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-btn
          v-if="roleTypeToCreate == 'Businesses'"
          elevation="0"
          color="primary"
          @click="addTextField()"
          ><v-icon>mdi-plus</v-icon>
          Add Row
        </v-btn>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-layout column>
        <v-checkbox
          label="Create Device and User Role(s)"
          class="mb-2"
          v-model="createDeviceAndUserRole"
          :disabled="roleTypeToCreate == 'Cities' || !relatedRole"
          hide-details
        ></v-checkbox>
        <v-btn
          v-if="!jobCreated"
          :disabled="!valid || !roleNames.length"
          color="primary"
          @click="createRole"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          Create {{ roleTypeToCreate }}
        </v-btn>
        <v-btn v-else color="success">
          <v-icon class="mr-2">mdi-check</v-icon>
          Action initiated
        </v-btn>
      </v-layout>
    </template>
  </TSidePanel>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      roleNames: [],
      jobCreated: false,
      valid: true,
      numberOfTextFields: 1,
      createDeviceAndUserRole: true,
      relatedRole: null,
      availableLocations: [],
      isLoading: false,
      existingLocations: [],
    };
  },
  computed: {
    roleTypeToCreate() {
      return this.group.type == "CountryRole" ? "Cities" : "Businesses";
    },
    validationRules: function () {
      return [
        (value) => !!value || "Required.",
        (value) => (value || "").length >= 2 || "Min 2 characters",
        (value) => (value || "").length <= 30 || "Max 30 characters",
        (value) =>
          !this.group.children ||
          !this.group.children.filter((x) => x.name == value).length ||
          this.roleTypeToCreate + " already exists",
        (value) =>
          !(this.roleNames.filter((x) => x == value).length > 1) ||
          value + " is already in the list",
      ];
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    async createRole() {
      let roleNames = this.roleNames;
      if (this.roleTypeToCreate == "Cities")
        roleNames = this.roleNames.map((x) => x.u_worldcode);

      this.$store.dispatch("createRole", {
        roleNames: roleNames,
        parent: this.group,
        roleTypeToCreate: this.roleTypeToCreate,
      });
      if (this.createDeviceAndUserRole && this.relatedRole) {
        this.$store.dispatch("createRole", {
          roleNames: roleNames,
          parent: this.relatedRole,
          roleTypeToCreate: this.roleTypeToCreate,
        });
      }
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
    getRelatedRole() {
      if (this.group.id) {
        this.relatedRole = null;
        mcmapi
          .GetRelatedRole(this.group.id)
          .then((response) => {
            this.relatedRole = response;
            this.createDeviceAndUserRole = true;
          })
          .catch(() => {
            this.relatedRole = null;
            this.createDeviceAndUserRole = false;
          });
      }
    },
    getSolveITLocationsOfACountry() {
      this.isLoading = true;
      if (this.group.id) {
        mcmapi
          .GetSolveITLocationsOfACountry(this.group.name)
          .then((response) => {
            this.availableLocations = response.result
              .filter(
                (x) => !this.existingLocations.some((y) => y == x.u_worldcode)
              )
              .sort((a, b) => (a.u_worldcode > b.u_worldcode ? 1 : -1));
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    addTextField() {
      this.roleNames.push(null);
    },
    deleteTextField(key) {
      this.$delete(this.roleNames, key);
    },
    comboBoxFilter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
          -1 ||
        item.name
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    async getExistingLocations() {
      await mcmapi
        .GetCitiesByCountryId(this.group.id)
        .then(
          (response) =>
            (this.existingLocations = response.map((resp) => resp.name))
        )
        .finally(() => {
          this.getSolveITLocationsOfACountry();
        });
    },
  },
  watch: {
    group: {
      handler() {
        this.getRelatedRole();
      },
      deep: true,
    },
  },
  mounted() {
    this.getRelatedRole();
    if (this.roleTypeToCreate == "Cities") {
      this.getExistingLocations();
    } else {
      this.roleNames.push(null);
    }
  },
};
</script>
