<template>
  <TSidePanel
    :showSearch="!itemsArePopulatedFromParent"
    :search.sync="search"
    hideSearchDetails
    v-on:close="$emit('close')"
  >
    <template v-slot:title> Add Configuration Profile </template>

    <template v-slot:top-title>
      {{
        itemsArePopulatedFromParent
          ? "Selected Roles (" + treeMode + ")"
          : "Available Configuration Profiles"
      }}</template
    >

    <template v-slot:top-content>
      <div v-if="treeMode === 'Users' && !itemsArePopulatedFromParent">
        <v-switch
          color="primary"
          v-model="showDeviceConfigurationProfiles"
          dense
          hide-details
          class="mt-0 mb-2 ml-2"
          inset
        >
          <template v-slot:label>
            <span class="font-weight-medium">Show Device CFGs</span>
            <v-tooltip
              top
              v-if="showDeviceConfigurationProfiles"
              max-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  color="error darken-1"
                  dark
                >
                  mdi-alert-circle
                </v-icon>
              </template>
              <span
                >Assigning Device Configuration Profiles to users only works if
                the users are the primary user of a device! (User centric
                management)</span
              >
            </v-tooltip>
          </template></v-switch
        >
      </div>
      <v-data-table
        v-if="!itemsArePopulatedFromParent"
        v-model="selectedItems"
        :items="items"
        :headers="headers"
        dense
        show-select
        :loading="isLoading"
        loader-height="1"
        :items-per-page="-1"
        :search="search"
        group-by="category"
        sort-by="shortName"
        loading-text="Loading Configuration Profiles"
      >
        <template v-slot:group.header="props">
          <td colspan="2" class="pointer pl-4 py-1" @click="props.toggle()">
            <v-icon>{{ props.isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
            <span class="ml-7 subtitle-2">{{ props.group }}</span>
          </td>
        </template>
      </v-data-table>

      <v-list v-else color="transparent" dense class="pt-0">
        <v-list-item
          v-for="role in selectedRoles"
          :key="role.id"
          class="pl-0 ma-0"
        >
          <v-list-item-icon
            ><v-icon>{{
              $store.getters.groupTypeIcon(role.type)
            }}</v-icon></v-list-item-icon
          >
          <span>
            {{ role.name }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:bottom-title>
      Selected Configuration Profiles ({{ selectedItems.length }})
    </template>

    <template v-slot:bottom-content>
      <v-list
        v-if="selectedItems.length"
        color="transparent"
        dense
        class="pt-0"
      >
        <v-list-item v-for="cp in selectedItems" :key="cp.id" class="pl-0 ma-0">
          <v-list-item-icon><v-icon>mdi-cog-box</v-icon></v-list-item-icon>
          <span>
            {{ cp.shortName }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!selectedItems.length"
        @click="addConfigurationProfilesToRoles"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add Configuration Profiles
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
    itemsArePopulatedFromParent: {
      type: Boolean,
      default: false,
    },
    itemsFromParent: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedRoles: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      allItems: [],
      headers: [{ text: "Name", value: "shortName" }],
      selectedItems: [],
      isLoading: false,
      jobCreated: false,
      search: null,
      showDeviceConfigurationProfiles: false,
    };
  },
  methods: {
    async getAvailableConfigurationProfiles() {
      this.allItems = [];
      this.isLoading = true;
      await mcmapi
        .GetConfigurationProfiles()
        .then((response) => {
          this.allItems = response;
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
    closeDialog() {
      this.$emit("close");
    },
    addConfigurationProfilesToRoles() {
      this.$store.dispatch("addRolesToAssignmentGroups", {
        groups: this.selectedItems,
        roles: this.selectedRoles,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
  computed: {
    treeMode() {
      return this.$store.state.treeMode;
    },
    items() {
      const cfgScope = this.showDeviceConfigurationProfiles
        ? "MCM_CFG_Devices_"
        : "MCM_CFG_" + this.treeMode + "_";
      return this.allItems
        .filter((obj) => {
          return obj.displayName.startsWith(cfgScope);
        })
        .map((obj) => {
          return {
            ...obj,
            shortName: obj.displayName.replace(cfgScope, ""),
            category: obj.displayName.split("_")[3],
          };
        });
    },
  },
  watch: {
    itemsFromParent(newVal) {
      this.selectedItems = newVal;
    },
  },
  created() {
    if (!this.itemsArePopulatedFromParent && this.group.id) {
      this.getAvailableConfigurationProfiles();
    } else {
      this.selectedItems = this.itemsFromParent;
    }
  },
};
</script>

<style lang="scss" scoped></style>
