<template>
  <div>
    <v-alert
      v-if="importMessage.length"
      dense
      type="error"
      :prominent="importMessage.length > 1"
      text
      outlined
    >
      <p v-for="(message, index) in importMessage" :key="index" class="ma-0">
        {{ message }}
      </p>
    </v-alert>
    <v-textarea
      :value="value"
      outlined
      :rows="rows"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
      @blur="$emit('input', reformatInput(value))"
      hide-details
      single-line
      class="mb-2"
    ></v-textarea>
    <v-btn color="primary" :disabled="!value" @click="processInput(value)"
      >Validate</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      importMessage: [],
    };
  },
  methods: {
    reformatInput(input) {
      if (input) {
        var tab = RegExp("\\t", "g");
        input = input.replace(tab, ",");
        input = input.replace(";", ",");
        input = input.replace(/\v/g, "");
        input = input.replace(" ", "");
        input = input.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        return input;
      }
    },
    processInput(input) {
      function findDuplicates(arr, key) {
        let sorted_arr = arr.slice().sort(compareValues(key));
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
          if (sorted_arr[i + 1][key] == sorted_arr[i][key]) {
            results.push(
              sorted_arr[i][key].substr(0, 25) +
                (sorted_arr[i][key].length > 25 ? "..." : "")
            );
          }
        }
        return results;
      }

      function compareValues(key, order = "asc") {
        return function innerSort(a, b) {
          if (
            !Object.prototype.hasOwnProperty.call(a, key) ||
            !Object.prototype.hasOwnProperty.call(b, key)
          )
            return 0;
          const comparison = a[key].localeCompare(b[key]);
          return order === "desc" ? comparison * -1 : comparison;
        };
      }

      // Reset all values to default
      this.importMessage = [];
      var lines = [];
      var data = [];
      var importMessage = [];

      if (input) {
        // Clean and split input by line
        lines = input.replace(/^\s+|\s+$|\s+(?=\s)/g, "").split("\n");
        lines = lines.filter(Boolean);

        if (!lines.length) {
          importMessage.push("Error: No data to import");
        } else {
          // Loop through all lines and push to importData array
          lines.forEach((line, lineIndex) => {
            let valuesObject = {};
            let values = line.split(",");

            values.forEach((value, index) => {
              let column = this.columns[index];
              if (!column) return;

              if (column.mandatory || !!value) {
                if (column.regex && !RegExp(column.regex).test(value)) {
                  importMessage.push(
                    "Error in line " +
                      (lineIndex + 1) +
                      ": " +
                      value +
                      " has wrong format"
                  );
                } else {
                  valuesObject[column.name] = value;
                }
              }
            });
            data.push(valuesObject);
          });
          // Check for duplicates in unique columns
          this.columns
            .filter((x) => x.unique)
            .forEach((column) => {
              let duplicates = findDuplicates(data, column.name);
              if (duplicates.length > 0) {
                importMessage.push(
                  duplicates.length +
                    " duplicate " +
                    column.name +
                    (duplicates.length > 1 ? "s were" : " was") +
                    " found (" +
                    duplicates.join(", ") +
                    ")"
                );
              }
            });

          // When no errors occured emit data to parent
          if (importMessage.length == 0) {
            this.$emit("validation-passed", data);
          }
        }
        // write Import Messages to Components Data Object
        this.importMessage = importMessage;
      }
    },
  },
};
</script>
