<template>
  <div>
    <v-row no-gutters class="py-3" justify="space-between">
      <v-col>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'addConfigurationProfileToRole',
              group.type,
              permission
            )
          "
          text
          class="font-weight-light text-capitalize"
          @click="showAddOthersDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>

          <span v-if="$vuetify.breakpoint.lgAndUp">Add</span>
        </v-btn>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'removeConfigurationProfileFromRole',
              group.type,
              permission
            )
          "
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showRemoveOthersDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-minus</v-icon>

          <span v-if="$vuetify.breakpoint.lgAndUp">Remove</span>
        </v-btn>
        <v-btn
          text
          class="font-weight-light text-capitalize"
          @click="getGroupMemberships()"
          :disabled="isLoading"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
        </v-btn>
      </v-col>
      <v-col cols="3" md="3" xl="2">
        <v-responsive width="auto" min-width="120">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            hide-details
            outlined
            rounded
          ></v-text-field>
        </v-responsive>
      </v-col>
    </v-row>

    <v-layout column style="height: calc(100vh - 190px)">
      <v-flex style="overflow: auto">
        <v-data-table
          v-model="selectedItems"
          @toggle-select-all="selectAllToggle"
          :items="items"
          :headers="headers"
          dense
          show-select
          :loading="isLoading"
          loader-height="1"
          :items-per-page="-1"
          :search="search"
          group-by="category"
          loading-text="Loading"
          no-data-text="Nothing assigned"
        >
          <template v-slot:group.header="props">
            <td colspan="3" class="pointer pl-4 py-1" @click="props.toggle()">
              <v-icon>{{ props.isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
              <span class="ml-7 subtitle-2">{{
                `${props.group} (${props.items.length})`
              }}</span>
            </td>
          </template>
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
              v-if="item.membership === 'Direct'"
            ></v-simple-checkbox>
            <v-icon v-else color="grey lighten-2"
              >mdi-checkbox-blank-off-outline</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      v-model="showAddOthersDialog"
      absolute
      right
      width="600"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <AddOthers
        v-if="showAddOthersDialog"
        :group="group"
        :assignmentGroupTypes="assignmentGroupTypes"
        :selectedRoles="$store.getters.selectedRoles($route.name)"
        v-on:close="
          showAddOthersDialog = false;
          getGroupMemberships();
        "
      ></AddOthers>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showRemoveOthersDialog"
      absolute
      right
      width="600"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <RemoveOthers
        v-if="showRemoveOthersDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="
          showRemoveOthersDialog = false;
          getGroupMemberships();
        "
      ></RemoveOthers>
    </v-navigation-drawer>
  </div>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import AddOthers from "@/components/Others/AddOthers";
import RemoveOthers from "@/components/Others/RemoveOthers";

export default {
  components: {
    AddOthers,
    RemoveOthers,
  },
  props: {
    group: {
      type: Object,
    },
    transitive: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
      default: "None",
    },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "Name", value: "shortName" },
        { text: "Membership", value: "membership" },
      ],
      isLoading: false,
      search: null,
      selectedItems: [],
      showAddOthersDialog: false,
      showRemoveOthersDialog: false,
      assignmentGroupTypesDevices: [
        { name: "OS Upgrades", tag: "OSU" },
        { name: "Enrollment Status Page", tag: "ESP" },
        { name: "Autopilot Deployment Profile", tag: "ADP" },
        { name: "Update Rings", tag: "UPM" },
      ],
      assignmentGroupTypesUsers: [
        //{ name: "Printers", tag: "PRT" },
        { name: "OS Upgrades", tag: "OSU" },
        { name: "Enrollment Status Page", tag: "ESP" },
      ],
    };
  },
  methods: {
    async getGroupMemberships() {
      if (!this.group.id) return;
      this.items = [];
      this.isLoading = true;
      const prefixRegEx = new RegExp(
        `^MCM_(${this.assignmentGroupTypes.map((z) => z.tag).join("|")})_.*$`
      );
      await mcmapi
        .GetMemberOf({ id: this.group.id })
        .then((response) => {
          this.items = response
            .filter((x) => x.type == "group" && prefixRegEx.test(x.displayName))
            .map((obj) => {
              let type = obj.displayName.substring(4, 7);
              return {
                ...obj,
                shortName: obj.displayName.replace(/MCM_[A-Z]{3}_/, ""),
                category: this.assignmentGroupTypes.filter(
                  (a) => a.tag === type
                )[0]?.name,
                membership: "Direct",
              };
            });
          this.selectedItems = [];
        })
        .catch((err) => console.warn(err));
      await mcmapi
        .GetMemberOf({ id: this.group.id, transitive: true })
        .then((response) => {
          //const prefixRegEx = new RegExp(`^MCM_(${this.assignmentGroupTypes.map((z) => z.tag).join("|")})_.*$`);

          this.items = this.items.concat(
            response
              .filter(
                (x) =>
                  x.type == "group" &&
                  prefixRegEx.test(x.displayName) &&
                  !this.items.some((y) => y.id === x.id)
              )
              .map((obj) => {
                let type = obj.displayName.substring(4, 7);
                return {
                  ...obj,
                  shortName: obj.displayName.replace(/MCM_[A-Z]{3}_/, ""),
                  category: this.assignmentGroupTypes.filter(
                    (a) => a.tag === type
                  )[0]?.name,
                  membership: "Inherited",
                };
              })
          );
          this.selectedItems = [];
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
        });
    },

    selectAllToggle(props) {
      let availableItems = props.items.filter(
        (item) => item.membership === "Direct"
      );
      if (this.selectedItems.length !== availableItems.length) {
        this.selectedItems = availableItems;
      } else {
        this.selectedItems = [];
      }
    },
  },
  computed: {
    Count() {
      return this.items.length;
    },
    assignmentGroupTypes() {
      return this.$store.state.currentActiveGroup[0]?.scope === "Devices"
        ? this.assignmentGroupTypesDevices
        : this.assignmentGroupTypesUsers;
    },
  },
  mounted() {
    if (this.group) this.getGroupMemberships();
  },
};
</script>

<style></style>
