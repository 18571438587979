<template>
  <v-card class="fill-height" min-width="400">
    <v-card-title class="pb-0"
      ><slot name="title"></slot>
      <v-spacer></v-spacer>
      <v-btn @click="closeDialog()" small class="mr-n3" fab text
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text class="pb-0">
      <v-card-subtitle class="font-weight-bold pl-0"
        ><slot name="top-title"></slot
      ></v-card-subtitle>
      <v-text-field
        v-if="showSearch"
        v-model="search"
        class="mb-2"
        prepend-inner-icon="mdi-magnify"
        :placeholder="searchLabel"
        dense
        outlined
        clearable
        :messages="searchMessage"
        :hide-details="hideSearchDetails"
      ></v-text-field>
      <v-layout
        column
        :style="`height: calc(${
          fullHeight ? '100vh' : '54vh'
        } - ${fixedHeight})`"
      >
        <v-flex align-start style="overflow: auto">
          <slot name="top-content"></slot>
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-text v-if="!fullHeight" class="pt-0">
      <v-card-subtitle class="font-weight-bold pl-0">
        <slot name="bottom-title"></slot>
      </v-card-subtitle>
      <v-layout column :style="`height: calc(54vh - ${fixedHeight})`">
        <v-flex style="overflow: auto">
          <slot name="bottom-content"></slot>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions style="position: absolute; bottom: 0">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    showSearch: {
      type: Boolean,
      default: false,
    },
    hideSearchDetails: {
      type: Boolean,
      default: false,
    },
    searchLabel: {
      type: String,
      default: "Search...",
    },
    searchMessage: {
      type: String,
      default: "",
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    extendedActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fixedHeight() {
      let searchHeight = this.showSearch ? 20 : 0;
      let actionHeight = this.extendedActions ? 70 : 0;
      let height = 180 + searchHeight + actionHeight;
      return `${this.fullHeight ? 1.5 * height : height}px`;
    },
    search: {
      get() {
        return null;
      },
      set(newVal) {
        this.$emit("update:search", newVal);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>
