<template>
  <div>
    <v-card v-if="currentActiveGroup" elevation="0">
      <v-toolbar flat dense color="white" class="pa-0 pl-2" ma-0>
        <v-btn-toggle
          v-model="currentView"
          group
          color="primary"
          mandatory
          borderless
          :dense="!$vuetify.breakpoint.lgAndUp"
        >
          <v-btn text class="ml-n2 font-weight-light text-capitalize">
            <v-icon size="25" color="primary" class="mr-1">{{
              currentActiveGroup.scope === "Devices"
                ? "mdi-laptop"
                : "mdi-account"
            }}</v-icon>
            <span class="text-capitalize" v-if="$vuetify.breakpoint.lgAndUp">{{
              currentActiveGroup.scope
            }}</span>
          </v-btn>
          <v-btn text class="font-weight-light text-capitalize">
            <v-icon size="25" color="primary" class="mr-1">mdi-apps</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp">Applications</span>
          </v-btn>
          <v-btn text class="font-weight-light text-capitalize">
            <v-icon size="25" color="primary" class="mr-1">mdi-cog-box</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp"
              >Configuration Profiles</span
            >
          </v-btn>
          <v-btn text class="font-weight-light text-capitalize">
            <v-icon size="25" color="primary" class="mr-1"
              >mdi-sticker-plus-outline</v-icon
            >
            <span v-if="$vuetify.breakpoint.lgAndUp">Other</span>
          </v-btn>
        </v-btn-toggle>

        <v-divider vertical></v-divider>

        <v-btn
          v-if="
            currentActiveGroup &&
            $store.getters.userHasPermission(
              'createCityRole',
              currentActiveGroup.type,
              permission
            )
          "
          text
          :small="!$vuetify.breakpoint.lgAndUp"
          class="font-weight-light text-capitalize"
          @click="showCreateRoleDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Create City</span>
        </v-btn>
        <v-btn
          v-if="
            currentActiveGroup &&
            $store.getters.userHasPermission(
              'createBusinessRole',
              currentActiveGroup.type,
              permission
            )
          "
          text
          :small="!$vuetify.breakpoint.lgAndUp"
          class="font-weight-light text-capitalize"
          @click="showCreateRoleDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Create Business</span>
        </v-btn>

        <v-btn
          v-if="
            currentActiveGroup &&
            $store.getters.userHasPermission(
              'deleteRole',
              currentActiveGroup.type,
              permission
            )
          "
          text
          :small="!$vuetify.breakpoint.lgAndUp"
          class="font-weight-light text-capitalize"
          @click="showDeleteRoleDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-delete</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp"
            >Delete
            {{
              currentActiveGroup.type == "CountryRole" ? "City" : "Business"
            }}</span
          >
        </v-btn>
        <v-btn
          v-if="currentActiveGroup"
          text
          class="font-weight-light text-capitalize"
          :href="
            'https://endpoint.microsoft.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/' +
            currentActiveGroup.id
          "
          target="New"
        >
          <v-icon size="25" color="primary" class="mr-1"
            >mdi-microsoft-azure</v-icon
          >
          <span v-if="$vuetify.breakpoint.lgAndUp">Open in Azure</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-tooltip
          v-if="
            currentActiveGroup &&
            !$store.getters.groupIsFavorite(currentActiveGroup.id)
          "
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              text
              color="orange darken-2"
              @click="addToFavorites()"
              ><v-icon size="26" color="orange darken-2"
                >mdi-star-plus-outline</v-icon
              ></v-btn
            >
          </template>
          <span>Add to Favorites</span>
        </v-tooltip>
        <v-tooltip
          v-if="
            currentActiveGroup &&
            $store.getters.groupIsFavorite(currentActiveGroup.id)
          "
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              text
              color="orange darken-2"
              @click="removeFromFavorites()"
              ><v-icon size="26" color="orange darken-2"
                >mdi-star</v-icon
              ></v-btn
            >
          </template>
          <span>Remove from Favorites</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text class="pa-1">
        <Devices
          v-if="
            currentActiveGroup &&
            currentView == 0 &&
            currentActiveGroup.scope === 'Devices'
          "
          :key="currentActiveGroup.id"
          :group="currentActiveGroup"
          :permission="permission"
          :transitive="currentActiveGroup.type == 'business' ? false : true"
        ></Devices>
        <Users
          v-if="
            currentActiveGroup &&
            currentView == 0 &&
            currentActiveGroup.scope === 'Users'
          "
          :key="currentActiveGroup.id"
          :group="currentActiveGroup"
          :permission="permission"
          :transitive="currentActiveGroup.type == 'business' ? false : true"
        ></Users>
        <Applications
          v-if="currentActiveGroup && currentView == 1"
          :key="currentActiveGroup.id"
          :group="currentActiveGroup"
          :permission="permission"
        ></Applications>
        <ConfigurationProfiles
          v-if="currentActiveGroup && currentView == 2"
          :key="currentActiveGroup.id"
          :group="currentActiveGroup"
          :permission="permission"
        ></ConfigurationProfiles>
        <Others
          v-if="currentActiveGroup && currentView == 3"
          :key="currentActiveGroup.id"
          :group="currentActiveGroup"
          :permission="permission"
        ></Others>
        <v-navigation-drawer
          v-model="showCreateRoleDialog"
          absolute
          right
          width="500px"
          temporary
          :overlay-opacity="0"
          height="100%"
        >
          <CreateRole
            v-if="showCreateRoleDialog"
            :key="currentActiveGroup.id"
            :group="currentActiveGroup"
            v-on:close="showCreateRoleDialog = false"
          ></CreateRole>
        </v-navigation-drawer>
        <v-navigation-drawer
          v-model="showDeleteRoleDialog"
          absolute
          right
          width="500px"
          temporary
          :overlay-opacity="0"
          height="100%"
        >
          <DeleteRole
            v-if="showDeleteRoleDialog"
            :key="currentActiveGroup.id"
            :group="currentActiveGroup"
            v-on:close="showDeleteRoleDialog = false"
          ></DeleteRole>
        </v-navigation-drawer>
      </v-card-text>
    </v-card>
    <v-card v-else elevation="0" class="ma-2">
      <v-skeleton-loader
        v-if="!message"
        type="heading, table"
      ></v-skeleton-loader>
      <v-card-text v-else class="text-center">
        <p class="text-h5">{{ message }}</p>
        <v-btn @click="initialize($route.params.roleId)" color="primary"
          ><v-icon class="mr-2">mdi-reload</v-icon>Reload</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import Devices from "@/components/Devices/RoleDevices.vue";
import Users from "@/components/Users/RoleUsers.vue";
import Applications from "@/components/Applications/RoleApplications.vue";
import ConfigurationProfiles from "@/components/ConfigurationProfiles/RoleConfigurationProfiles.vue";
import Others from "@/components/Others/RoleOthers.vue";
import CreateRole from "@/components/Roles/CreateRole.vue";
import DeleteRole from "@/components/Roles/DeleteRole.vue";

export default {
  components: {
    Devices,
    Users,
    Applications,
    ConfigurationProfiles,
    Others,
    CreateRole,
    DeleteRole,
  },
  data() {
    return {
      currentView: 0,
      showCreateRoleDialog: false,
      showDeleteRoleDialog: false,
      permission: "None",
      message: null,
    };
  },
  methods: {
    addToFavorites() {
      this.$store.dispatch("addGroupToFavorites", this.currentActiveGroup);
    },
    removeFromFavorites() {
      this.$store.dispatch("RemoveGroupFromFavorites", this.currentActiveGroup);
    },
    async getRole(id) {
      this.message = null;
      return mcmapi
        .GetRoleById(id)
        .then((response) => {
          if (response.values.length == 1) {
            const resp = response.values[0];
            const split = resp.mcmType.split("_");
            const path = resp.displayName.split("_").slice(2);
            const role = {
              ...resp,
              scope: split[1],
              type: split[2],
              path: path,
            };
            this.$store.commit("setCurrentActiveGroup", [role]);
          }
        })
        .catch(() => {
          this.message =
            "Group not found! If the group was created recently, hit this button in a few seconds:";
        });
    },
    async getPermission(id) {
      this.permission = "None";
      return mcmapi.GetPermissionOfARole(id).then((response) => {
        this.permission = response.permission;
      });
    },
    initialize(id) {
      this.getRole(id);
      this.getPermission(id);
    },
  },
  computed: {
    currentActiveGroup() {
      return this.$store.state.currentActiveGroup[0];
    },
  },
  watch: {
    "$route.params.roleId"(id) {
      this.initialize(id);
    },
  },
  mounted() {
    this.initialize(this.$route.params.roleId);
  },
};
</script>

<style></style>
