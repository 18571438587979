<template>
  <div>
    <UserDetails
      :userId="$route.params.userId"
      :key="$route.params.userId"
    ></UserDetails>
  </div>
</template>

<script>
import UserDetails from "@/components/Users/UserDetails";

export default {
  components: {
    UserDetails,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
