<template>
  <v-card elevation="0">
    <v-card-text class="pa-1">
      <v-row no-gutters class="py-3" justify="space-between">
        <v-col class="mb-0">
          <v-btn
            text
            class="font-weight-light text-capitalize"
            :disabled="
              !selectedItems.length ||
              !$store.getters.selectedRoles($route.name).length
            "
            @click="showAddApplicationDialog = true"
          >
            <v-icon size="25" color="primary" class="mr-1"
              >mdi-application-export</v-icon
            >
            <span v-if="$vuetify.breakpoint.lgAndUp">Add to Roles</span>
          </v-btn>
          <v-btn
            text
            class="font-weight-light text-capitalize"
            @click="getApplications()"
            :disabled="isLoading"
          >
            <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
          </v-btn>
          <v-btn
            text
            class="font-weight-light text-capitalize"
            href="https://solveit.service-now.com/sp?id=sc_cat_item&sys_id=b658a19cdbfbb4108a38ba03f3961993&sysparm_category=c6dc6d9ddb139c10bba4ba03f396199e&catalog_id=-1"
            target="_new"
          >
            <v-icon size="25" color="primary" class="mr-1"
              >mdi-comment-plus-outline</v-icon
            >
            <span v-if="$vuetify.breakpoint.lgAndUp">Request Application</span>
          </v-btn>
          <v-btn
            text
            class="font-weight-light text-capitalize"
            href="https://endpoint.microsoft.com/#blade/Microsoft_Intune_DeviceSettings/AppsWindowsMenu/windowsApps"
            target="New"
          >
            <v-icon size="25" color="primary" class="mr-1"
              >mdi-microsoft-azure</v-icon
            >
            <span v-if="$vuetify.breakpoint.lgAndUp">Open in Azure</span>
          </v-btn>
        </v-col>
        <v-col cols="12" md="3" xl="2" class="mb-0">
          <v-responsive width="auto" min-width="120">
            <v-text-field
              v-model="search"
              :error-messages="message"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
              single-line
              dense
              outlined
              rounded
            ></v-text-field>
          </v-responsive>
        </v-col>
      </v-row>

      <v-layout column style="height: calc(100vh - 155px)">
        <v-flex style="overflow-y: auto; overflow-x: hidden">
          <v-data-table
            v-model="selectedItems"
            :items="items"
            :headers="headers"
            dense
            show-select
            :loading="isLoading"
            loader-height="1"
            :items-per-page="-1"
            checkbox-color="primary"
            loading-text="Loading Applications"
            hide-default-footer
            :options.sync="options"
            sort-by="displayName"
          >
            <template v-slot:item.displayName="{ item }">
              <span
                class="pointer"
                @click="openApplicationDetailsDialog(item)"
                >{{ item.displayName }}</span
              >
            </template>
            <template v-slot:item.appType="{ item }">
              <span
                v-if="
                  (item.appType ==
                    '#microsoft.graph.microsoftStoreForBusinessApp') |
                    (item.appType == '#microsoft.graph.winGetApp')
                "
              >
                Store App
              </span>
              <span v-else> Win32 App </span>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-flex class="text-center pt-1">
                <v-btn
                  :disabled="isLoading || !loadMoreButtonIsActive"
                  :loading="isLoading"
                  text
                  color="primary"
                  @click="getApplications({ append: true })"
                  >Load more</v-btn
                >
              </v-flex>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-navigation-drawer
        v-model="showAddApplicationDialog"
        absolute
        right
        width="700"
        temporary
        :overlay-opacity="0"
        height="100%"
      >
        <AddApplications
          v-if="showAddApplicationDialog"
          :itemsArePopulatedFromParent="true"
          :itemsFromParent="selectedItems"
          :selectedRoles="$store.getters.selectedRoles($route.name)"
          v-on:close="showAddApplicationDialog = false"
        ></AddApplications>
      </v-navigation-drawer>

      <v-navigation-drawer
        v-model="showApplicationsDetailsDialog"
        absolute
        right
        width="800"
        temporary
        :overlay-opacity="0"
        height="100%"
      >
        <ApplicationDetails
          :appId="clickedAppId"
          v-if="showApplicationsDetailsDialog"
          :embedded="true"
          v-on:close="showApplicationsDetailsDialog = false"
        ></ApplicationDetails>
      </v-navigation-drawer>
    </v-card-text>
  </v-card>
</template>

<script>
import graph from "@/Services/graph";
import AddApplications from "@/components/Applications/AddApplications";
import ApplicationDetails from "@/components/Applications/ApplicationDetails";

export default {
  components: {
    AddApplications,
    ApplicationDetails,
  },
  data() {
    return {
      selectedItems: [],
      items: [],
      headers: [
        { text: "Name", value: "displayName" },
        { text: "Publisher", value: "publisher", sortable: false },
        { text: "Type", value: "appType", width: 150, sortable: false },
        { text: "Version", value: "displayVersion", sortable: false },
      ],
      isLoading: false,
      search: null,
      message: null,
      options: {},
      showAddApplicationDialog: false,
      showApplicationsDetailsDialog: false,
      clickedAppId: null,
      loadMoreButtonIsActive: false,
    };
  },
  methods: {
    async getApplications(options = { append: false }) {
      let timeout = 700;
      clearTimeout(this.searchTimer);
      this.message = "";
      this.searchTimer = setTimeout(async () => {
        const { sortBy, sortDesc } = this.options;
        if (!options.append) this.items = [];
        this.isLoading = true;
        const skip = this.items.length;
        const top = 20 + skip;
        await graph
          .getApplications(sortBy, sortDesc, skip, top, this.search)
          .then((response) => {
            response.value.forEach((element) => {
              this.items.push({ ...element, appType: element["@odata.type"] });
            });
            this.loadMoreButtonIsActive = !!response["@odata.nextLink"];
            this.selectedItems = [];
          })
          .catch((err) => console.warn(err))
          .finally(() => {
            this.isLoading = false;
          });
      }, timeout);
    },
    openApplicationDetailsDialog(app) {
      this.clickedAppId = app.id;
      this.showApplicationsDetailsDialog = true;
    },
  },
  watch: {
    search() {
      this.getApplications();
    },
    options: {
      handler() {
        this.getApplications();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
