<template>
  <v-menu bottom min-width="250px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" fab text small class="mx-1">
        <v-avatar size="42" color="primary">
          <v-icon color="white" v-if="!user || !photo"> mdi-account </v-icon>
          <img v-else :src="photo" alt="photo" />
        </v-avatar>
        <p
          v-if="!user"
          class="font-weight-light text-h4 text-capitalize ml-6 mt-3"
        >
          Sign In
        </p>
      </v-btn>
    </template>
    <v-card class="px-5">
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar color="primary" size="65" class="ma-3">
            <v-icon dark v-if="!photo" large> mdi-account </v-icon>
            <img :src="photo" alt="photo" v-else />
          </v-avatar>
          <div v-if="user">
            <h4>{{ user.idTokenClaims.name }}</h4>
            <p class="text-caption mt-1">
              {{ user.idTokenClaims.preferred_username }}
            </p>
            <v-divider class="my-1"></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  text
                  href="https://solveit.service-now.com/sp?id=sc_category&sys_id=c6dc6d9ddb139c10bba4ba03f396199e&catalog_id=e0d08b13c3330100c8b837659bba8fb4"
                  target="_new"
                  ><v-icon>mdi-comment-plus-outline</v-icon></v-btn
                >
              </template>
              <span>SolveIT Intune Requests</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  text
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=yBnxDeoj2UCN2mLUpNuN-5IY65Dd3IVOoLOx_CGUYVNUREZVSkNTQU81UjJLUEVTS09ZUU1ZMFc4Uy4u"
                  target="_new"
                >
                  <v-icon>mdi-account-voice</v-icon>
                </v-btn>
              </template>
              <span>Send Feedback</span>
            </v-tooltip>
            <v-divider class="my-1"></v-divider>
            <v-btn depressed text @click="signOut"> Sign out </v-btn>
          </div>
          <div v-else>
            <v-btn depressed text @click="signIn"> Sign in </v-btn>
          </div>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import auth from "@/Services/auth";
import graph from "@/Services/graph";
import log from "@/Services/log";

export default {
  data() {
    return {
      photo: null,
      meData: null,
    };
  },
  methods: {
    async signIn() {
      await auth.login();
      this.$store.commit("setUser", auth.user());
    },
    async signOut() {
      auth.logout();
      auth.clearLocal();
      this.photo = null;
      this.$store.commit("setUser", null);
    },
    async getPhoto() {
      if (this.user) {
        this.photo = await graph.getPhoto();
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    user: async function () {
      log.Info("User changed reading data");
      if (this.user) {
        this.getPhoto();
      }
    },
  },
  mounted() {
    this.getPhoto();
  },
};
</script>
