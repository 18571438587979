<template>
  <TSidePanel
    :showSearch="!itemsArePopulatedFromParent"
    extendedActions
    :search.sync="search"
    hideSearchDetails
    v-on:close="$emit('close')"
  >
    <template v-slot:title> Add Applications </template>

    <template v-slot:top-title>
      {{
        itemsArePopulatedFromParent
          ? "Selected Roles (" + treeMode + ")"
          : "Available Applications"
      }}</template
    >

    <template v-slot:top-content>
      <v-data-table
        v-if="!itemsArePopulatedFromParent"
        :items="items"
        :headers="headers"
        dense
        show-select
        v-model="selectedItems"
        :loading="isLoading"
        :loader-height="2"
        sort-by="displayName"
        :items-per-page="-1"
        hide-default-footer
        :options.sync="options"
        @input="getApplicationsAssignmentGroups($event)"
      >
        <template v-slot:item.description="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-divider></v-divider>
          <v-flex class="text-center pt-1">
            <v-btn
              :disabled="isLoading || !loadMoreButtonIsActive"
              :loading="isLoading"
              text
              color="primary"
              @click="getAvailableApplications({ append: true })"
              >Load more</v-btn
            >
          </v-flex>
        </template>
      </v-data-table>

      <v-list v-else color="transparent" dense class="pt-0">
        <v-list-item
          v-for="role in selectedRoles"
          :key="role.id"
          class="pl-0 ma-0"
        >
          <v-list-item-icon
            ><v-icon>{{
              $store.getters.groupTypeIcon(role.type)
            }}</v-icon></v-list-item-icon
          >
          <span>
            {{ role.name }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:bottom-title>
      Selected Applications ({{ selectedItems.length }})
    </template>

    <template v-slot:bottom-content>
      <v-list
        v-if="selectedItems.length"
        color="transparent"
        dense
        class="pt-0"
      >
        <v-list-item
          v-for="app in selectedItems"
          :key="app.id"
          class="pl-0 ma-0"
        >
          <v-list-item-icon><v-icon>mdi-apps</v-icon></v-list-item-icon>
          <span>
            {{ app.displayName }}
          </span>
          <v-chip
            small
            color="error"
            class="ml-2"
            v-if="
              selectedAssignmentType &&
              !assignmentGroups[app.id]?.some(
                (x) => x.intent === selectedAssignmentType
              )
            "
            >Install Type not available</v-chip
          >
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:actions>
      <v-layout column>
        <v-card-subtitle
          class="font-weight-bold pl-2 pb-1 grey--text text--darken-1"
          >Select Install Type</v-card-subtitle
        >
        <v-radio-group
          v-model="selectedAssignmentType"
          :disabled="!selectedItems.length"
          dense
          class="mt-1 mb-4 ml-2"
          hide-details
        >
          <v-radio
            v-for="type in assignmentTypes"
            :key="type.value"
            :label="type.text"
            :value="type.value"
            class="pa-0 ma-0"
          ></v-radio>
        </v-radio-group>
        <v-btn
          v-if="!jobCreated"
          color="primary"
          :disabled="
            !assignableAppAssignmentGroups.length ||
            !selectedAssignmentType ||
            !selectedItems.length
          "
          @click="addApplicationsToRoles"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          Add Applications
        </v-btn>
        <v-btn v-else color="success">
          <v-icon class="mr-2">mdi-check</v-icon>
          Action initiated
        </v-btn>
      </v-layout>
    </template>
  </TSidePanel>
</template>

<script>
import graph from "@/Services/graph";
import mcmapi from "@/Services/mcm-api";
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
    itemsArePopulatedFromParent: {
      type: Boolean,
      default: false,
    },
    itemsFromParent: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedRoles: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "Name", value: "displayName" },
        { text: "Version", value: "displayVersion", sortable: false },
        { text: "", value: "description", width: "30", sortable: false },
      ],
      selectedItems: [],
      selectedAssignmentType: null,
      assignmentTypes: [
        { text: "Install Required", value: "required" },
        { text: "Install Available", value: "available" },
        { text: "Uninstall Required", value: "uninstall" },
      ],
      assignmentGroups: {},
      isLoading: false,
      jobCreated: false,
      loadMoreButtonIsActive: false,
      search: null,
      options: {},
    };
  },
  methods: {
    async getAvailableApplications(options = { append: false }) {
      let timeout = 700;
      clearTimeout(this.searchTimer);
      this.message = "";
      this.searchTimer = setTimeout(async () => {
        const { sortBy, sortDesc } = this.options;
        if (!options.append) this.items = [];
        this.isLoading = true;
        const skip = this.items.length;
        const top = 20 + skip;
        await graph
          .getApplications(sortBy, sortDesc, skip, top, this.search)
          .then((response) => {
            this.items.concat(response.value);
            this.loadMoreButtonIsActive = !!response["@odata.nextLink"];

            response.value.forEach((element) => {
              this.items.push(element);
            });
          })
          .catch((err) => console.warn(err))
          .finally(() => {
            this.isLoading = false;
          });
      }, timeout);
    },
    async getApplicationsAssignmentGroups(apps) {
      apps.forEach((app) => {
        console.log("vor dem if");
        if (!this.assignmentGroups[app.id]) {
          console.log("nach dem if");
          this.$set(this.assignmentGroups, app.id, []);
          let checkedAssignments = [];
          graph
            .getApplicationAssignmentGroups(app.id)
            .then((response) => {
              response.assignments.forEach((assignment) => {
                if (assignment.target.groupId) {
                  mcmapi
                    .GetAssignmentGroup(assignment.target.groupId)
                    .then((response) => {
                      //let regExString = "^MCM_SWD_[a-zA-Z0-9_\\- ]+" + assignment.intent + "[a-zA-Z0-9_]*$";
                      //eslint-disable-next-line                      
                      let regExString = "^MCM_SWD_.+" + assignment.intent + "[a-zA-Z0-9_]*$";
                      if (
                        RegExp(regExString, "gi").test(response.displayName) && !assignment.id.endsWith("1")
                      ) {
                        checkedAssignments.push(assignment);
                      }
                    })
                    .catch(() => {
                      console.warn(
                        `${assignment.target.groupId} is not a valid assignment group`
                      );
                    });
                }
              });
            })
            .catch((err) => console.warn(err))
            .finally(() => {
              this.$set(this.assignmentGroups, app.id, checkedAssignments);
            });
        }
      });
    },
    closeDialog() {
      this.$emit("close");
    },
    addApplicationsToRoles() {
      this.$store.dispatch("addRolesToApplicationGroups", {
        apps: this.assignableAppAssignmentGroups,
        roles: this.selectedRoles,
        assignmentType: this.selectedAssignmentType,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
  computed: {
    treeMode() {
      return this.$store.state.treeMode;
    },
    assignableAppAssignmentGroups() {
      return this.selectedItems
        .map((x) => ({
          displayName: x.displayName,
          groupId:
            this.assignmentGroups[x.id]?.filter(
              (y) => y.intent === this.selectedAssignmentType
            )[0]?.target?.groupId ?? null,
        }))
        .filter((z) => z.groupId != null);
    },
  },
  watch: {
    itemsFromParent(newVal) {
      this.selectedItems = newVal;
    },
    search() {
      this.getAvailableApplications();
    },
    options: {
      handler() {
        this.getAvailableApplications();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.itemsArePopulatedFromParent && this.group.id) {
      this.getAvailableApplications();
    } else {
      this.selectedItems = this.itemsFromParent;
      this.getApplicationsAssignmentGroups(this.itemsFromParent);
    }
  },
};
</script>

<style lang="scss" scoped></style>
