import auth from "./auth";

const GRAPH_BASE = "https://graph.microsoft.com/beta";
//const GRAPH_SCOPES = ["user.read", "user.readbasic.all"];
const GRAPH_SCOPES = ["user.read"];

let accessToken;

export default {
  //
  // Get profile of logged on user
  //
  async getMe() {
    let resp = await callGraph("/me");
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  //
  // Get photo as blob
  //
  async getPhoto() {
    let resp = await callGraph("/me/photo/$value");
    if (resp) {
      let blob = await resp.blob();
      return URL.createObjectURL(blob);
    }
  },

  //
  // Get photo of a user
  //
  async getPhotoOfAUser(userId) {
    if (!userId) return;
    try {
      let resp = await callGraph(`/users/${userId}/photo/$value`);
      if (resp) {
        let blob = await resp.blob();
        return URL.createObjectURL(blob);
      }
    } catch {
      return null;
    }
  },

  //
  // SearchUsers
  //
  async searchUsers(searchString, max = 50) {
    let resp = await callGraph(
      `/users?$filter=startswith(displayName, '${searchString}') or startswith(userPrincipalName, '${searchString}')&$top=${max}`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  //
  // GetManagedDevice
  //
  async getManagedDeviceInfos(deviceObjectId) {
    let resp = await callGraph(
      `/deviceManagement/managedDevices?$filter=azureADDeviceId eq '${deviceObjectId}'`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  //
  // Trigger Device Actions
  //
  async triggerDeviceAction(deviceId, action) {
    return await callGraph(
      `/deviceManagement/managedDevices/${deviceId}/${action}`,
      "POST"
    );
  },

  //
  // Get Managed Device Managed Apps
  //
  async getManagedDeviceManagedApps(primaryUserId, deviceId) {
    const userId = primaryUserId
      ? primaryUserId
      : "00000000-0000-0000-0000-000000000000";
    let resp = await callGraph(
      `/users('${userId}')/mobileAppIntentAndStates('${deviceId}')`
    );
    if (resp) {
      let data = await resp.json();
      return data.mobileAppList;
    }
  },
  //
  // Delete Intune Device
  //
  async deleteIntuneDevice(deviceId) {
    let resp = await callGraph(
      `/deviceManagement/managedDevices/{'${deviceId}'`,
      "Delete"
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  //
  // GetManagedDevices
  //

  async getManagedDevices(
    sortBy = "deviceName",
    sortDesc = "asc",
    page = 1,
    itemsPerPage = 25,
    search = null
  ) {
    const skip = (page - 1) * itemsPerPage;
    const top = itemsPerPage + skip;
    const searchFilter = search
      ? ` and (contains(activationlockbypasscode, '${search}'))`
      : "";
    const filters = `$filter=(((deviceType eq 'desktop') or (deviceType eq 'windowsRT') or (deviceType eq 'winEmbedded') or (deviceType eq 'surfaceHub') or (deviceType eq 'desktop') or (deviceType eq 'windowsRT') or (deviceType eq 'winEmbedded') or (deviceType eq 'surfaceHub') or (deviceType eq 'windowsPhone') or (deviceType eq 'holoLens')${searchFilter}))`;

    let orderBy = "";
    if (sortBy.length === 1 && sortDesc.length === 1) {
      orderBy = `$orderBy=${sortBy[0]} ${sortDesc[0] ? "desc" : ""}`;
    }
    let resp = await callGraph(
      `/deviceManagement/managedDevices?${orderBy}&$top=${top}&skip=${skip}&${filters}`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }

    /*if (resp) {
      let data = await resp.json();
      let count = await this.getNumberOfManagedDevices(orderBy, top, skip, filters);
      return { data, count };
    }*/
  },
  async getNumberOfManagedDevices(orderBy, top, skip, filters) {
    let resp = await callGraph(
      `/deviceManagement/managedDevices?${orderBy}&$top=${top}&skip=${skip}&${filters}&$count=true`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  async getPrimaryDevicesOfAUser(userId) {
    let resp = await callGraph(`/users/${userId}/managedDevices`);
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  async getPrimaryUserOfADevice(deviceId) {
    let resp = await callGraph(
      `/deviceManagement/manageddevices/${deviceId}/users`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  async getGroupMembershipsOfADevice(deviceId, transitive = false) {
    let resp = await callGraph(
      `/devices(deviceId='${deviceId}')/${
        transitive ? "transitiveMemberOf" : "memberOf"
      }?$Top=999`
    );
    if (resp) {
      let data = await resp.json();
      return data.value;
    }
  },
  async getUserByUPN(userUPN) {
    let resp = await callGraph(
      `/users?$filter=userPrincipalName eq '${userUPN}'`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  // Get Autopilot Devices
  async getAutopilotDevices() {
    let resp = await callGraph(
      `/deviceManagement/windowsAutopilotDeviceIdentities?$filter=startswith(groupTag,'DELEJ01')`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  // Import Autopilot Device
  async importAutopilotDevice(autoPilotDevice) {
    return await callGraph(
      "/deviceManagement/importedWindowsAutopilotDeviceIdentities",
      "Post",
      autoPilotDevice
    );
  },

  //
  // Get Intune Applications
  //

  async getApplications(
    sortBy = "displayName",
    sortDesc = "asc",
    skip,
    top,
    search = null
  ) {
    let orderBy = "";
    if (sortBy.length === 1 && sortDesc.length === 1) {
      orderBy = `&$orderBy=${sortBy[0]} ${sortDesc[0] ? "desc" : ""}`;
    }
    const searchString = search ? `$search="${search}"&` : "";
    const filter =
      "(isof(%27microsoft.graph.windowsStoreApp%27)%20or%20isof(%27microsoft.graph.microsoftStoreForBusinessApp%27)%20or%20isof(%27microsoft.graph.officeSuiteApp%27)%20or%20isof(%27microsoft.graph.win32LobApp%27)%20or%20isof(%27microsoft.graph.windowsMicrosoftEdgeApp%27)%20or%20isof(%27microsoft.graph.windowsPhone81AppX%27)%20or%20isof(%27microsoft.graph.windowsPhone81StoreApp%27)%20or%20isof(%27microsoft.graph.windowsPhoneXAP%27)%20or%20isof(%27microsoft.graph.windowsAppX%27)%20or%20isof(%27microsoft.graph.windowsMobileMSI%27)%20or%20isof(%27microsoft.graph.windowsUniversalAppX%27)%20or%20isof(%27microsoft.graph.webApp%27)%20or%20isof(%27microsoft.graph.windowsWebApp%27)%20or%20isof(%27microsoft.graph.winGetApp%27))%20and%20(microsoft.graph.managedApp/appAvailability%20eq%20null%20or%20microsoft.graph.managedApp/appAvailability%20eq%20%27lineOfBusiness%27%20or%20isAssigned%20eq%20true)";
    let resp = await callGraph(
      `/deviceAppManagement/mobileApps?${searchString}$filter=${filter}${orderBy}&$top=${top}&skip=${skip}`
    );

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getApplicationDetails(appId) {
    let resp = await callGraph(`/deviceAppManagement/mobileApps/${appId}`);

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getApplicationDependencies(appId) {
    let resp = await callGraph(
      `/deviceAppManagement/mobileApps/${appId}/relationships?$filter=targetType%20eq%20microsoft.graph.mobileAppRelationshipType%27child%27`
    );

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getApplicationAssignmentGroups(appId) {
    let resp = await callGraph(
      `/deviceAppManagement/mobileApps/${appId}/?$expand=assignments`
    );

    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  getAccessToken() {
    return accessToken;
  },
};

//
// Common fetch wrapper (private)
//
async function callGraph(apiPath, method = "Get", body = null) {
  // Acquire an access token to call APIs (like Graph)
  // Safe to call repeatedly as MSAL caches tokens locally

  accessToken = await auth.acquireToken(GRAPH_SCOPES);
  let resp;

  if (method === "Post") {
    resp = await fetch(`${GRAPH_BASE}${apiPath}`, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        authorization: `Bearer ${accessToken}`,
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  } else {
    resp = await fetch(`${GRAPH_BASE}${apiPath}`, {
      method: method,
      headers: {
        authorization: `Bearer ${accessToken}`,
        accept: "application/json",
        "Content-Type": "application/json",
        ConsistencyLevel: "eventual",
      },
    });
  }

  if (!resp.ok) {
    throw new Error(
      `Call to ${GRAPH_BASE}${apiPath} failed: ${resp.statusText}`
    );
  }

  return resp;
}
