import Vue from "vue";
import Vuex from "vuex";
import mcmapi from "@/Services/mcm-api";
import graph from "@/Services/graph";
import * as constants from "@/modules/constants.js";

Vue.use(Vuex);

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default new Vuex.Store({
  state: {
    user: null,
    currentActiveGroup: [{}],
    currentActiveObjectName: null,
    treeMode: "Devices",
    selectedDeviceRoles: [],
    selectedUserRoles: [],
    selectedapplications: [],
    permissions: {},
    favorites: [],
    jobList: [],
  },
  getters: {
    currentPermission: (state) => {
      if (
        state.currentActiveGroup.length == 1 &&
        state.permissions[state.currentActiveGroup[0].countryId]
      )
        return state.permissions[state.currentActiveGroup[0].countryId];
      else return "None";
    },
    permissionForCountry: (state) => (countryId) => {
      if (countryId && state.permissions[countryId])
        return state.permissions[countryId];
      else return "None";
    },
    userHasPermission: (state, getters) => (action, groupType, permission) => {
      if (getters.permissionMatrix[action]) {
        return getters.permissionMatrix[action][permission][groupType];
      } else return false;
    },
    groupTypeIcon: () => (groupType) => {
      return constants.GROUP_TYPE_ICONS[groupType];
    },
    permissionMatrix: () => {
      return constants.PERMISSION_MATRIX;
    },
    groupIsFavorite: (state) => (groupId) => {
      return !!state.favorites.filter((x) => x.id == groupId).length;
    },
    selectedRoles: (state) => (route) => {
      if (route == "Roles") return state.currentActiveGroup;
      else if (state.treeMode == "Devices") {
        return state.selectedDeviceRoles;
      } else if (state.treeMode == "Users") {
        return state.selectedUserRoles;
      }
    },
    favorites: (state) => {
      return state.favorites.filter((x) => x.scope == state.treeMode);
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setPermission(state, data) {
      state.permissions = data;
    },
    setCurrentActiveGroup(state, group) {
      state.currentActiveGroup = group;
    },
    setCurrentActiveObjectName(state, name) {
      state.currentActiveObjectName = name;
    },
    setTreeMode(state, mode) {
      state.treeMode = mode;
    },
    setSelectedDeviceRoles(state, roles) {
      Vue.set(state, "selectedDeviceRoles", [...roles]);
    },
    setSelectedUserRoles(state, roles) {
      Vue.set(state, "selectedUserRoles", [...roles]);
    },
    addFavorite(state, favorite) {
      state.favorites.push(favorite);
    },
    removeFavorite(state, favorite) {
      state.favorites = state.favorites.filter((x) => x.id != favorite.id);
    },
    replaceFavorites(state, favorites) {
      state.favorites = favorites;
    },
    addJobtoJobList(state, job) {
      state.jobList.unshift(job);
    },
    updateJobFromJobList(state, { id, property, value }) {
      const index = state.jobList.findIndex((item) => item.id === id);
      Vue.set(state.jobList[index], property, value);
      Vue.set(state.jobList[index], "time", new Date());
    },
    setJobList(state, jobList) {
      state.jobList = jobList;
    },
  },
  actions: {
    loadFavoritesFromLocalStorage({ commit }) {
      if (localStorage.getItem("favorites")) {
        commit(
          "replaceFavorites",
          JSON.parse(localStorage.getItem("favorites"))
        );
      }
    },
    addGroupToFavorites({ commit, state }, favorite) {
      commit("addFavorite", favorite);
      localStorage.setItem("favorites", JSON.stringify(state.favorites));
    },
    RemoveGroupFromFavorites({ commit, state }, favorite) {
      commit("removeFavorite", favorite);
      localStorage.setItem("favorites", JSON.stringify(state.favorites));
    },
    async createRole(
      { commit, dispatch },
      { parent, roleNames, roleTypeToCreate }
    ) {
      let method =
        roleTypeToCreate == "Cities"
          ? mcmapi.createCity
          : mcmapi.createBusiness;
      await dispatch("createJob", {
        items: roleNames,
        jobName: `${parent.name}: Create ${roleNames.length} ${roleTypeToCreate}`,
      }).then((job) => {
        parent.job = job;
      });
      await asyncForEach(roleNames, async (roleName, index) => {
        await method(roleName, parent.id)
          .then(() => {
            parent.job.steps[index].status = "success";
          })
          .catch((err) => {
            if (err.errors) {
              parent.job.steps[index].error = err.errors;
            } else {
              parent.job.steps[index].error = err.title;
            }
            parent.job.steps[index].status = "error";
            parent.job.errorCount++;
            commit("updateJobFromJobList", {
              id: parent.job.id,
              property: "errorCount",
              value: parent.job.errorCount,
            });
          })
          .finally(() => {
            parent.job.completedSteps++;
            commit("updateJobFromJobList", {
              id: parent.job.id,
              property: "completedSteps",
              value: parent.job.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: parent.job.id,
              property: "steps",
              value: parent.job.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: parent.job.id,
        property: "status",
        value: "finished",
      });
    },
    async deleteRoles(
      { commit, dispatch },
      { parent, groups, roleTypeToDelete }
    ) {
      let method =
        roleTypeToDelete == "Cities"
          ? mcmapi.deleteCity
          : mcmapi.deleteBusiness;
      await dispatch("createJob", {
        items: groups,
        jobName: `${parent.name}: Delete ${groups.length} ${roleTypeToDelete}`,
        nameProp: "displayName",
      }).then((job) => {
        parent.job = job;
      });

      await asyncForEach(groups, async (group, index) => {
        //parent.job.steps[index].status = "running";
        await method(group.id)
          .then(() => {
            parent.job.steps[index].status = "success";
          })
          .catch((err) => {
            parent.job.steps[index].error = err.title;
            parent.job.steps[index].status = "error";
            parent.job.errorCount++;
            commit("updateJobFromJobList", {
              id: parent.job.id,
              property: "errorCount",
              value: parent.job.errorCount,
            });
          })
          .finally(() => {
            parent.job.completedSteps++;
            commit("updateJobFromJobList", {
              id: parent.job.id,
              property: "completedSteps",
              value: parent.job.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: parent.job.id,
              property: "steps",
              value: parent.job.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: parent.job.id,
        property: "status",
        value: "finished",
      });
    },
    async addDevicesToBusinessRole({ dispatch }, { devices, group }) {
      await dispatch("createJob", {
        items: devices,
        jobName: `${group.name}: Add ${devices.length} Member${
          devices.length == 1 ? "" : "s"
        }`,
        nameProp: "displayName",
      }).then((job) => {
        group.job = job;
      });
      await dispatch("modifyMembership", {
        members: devices,
        group: group,
        method: mcmapi.AddDeviceToBusinessRole,
        job: group.job,
      });
    },
    async removeDevicesFromBusinessRole({ dispatch }, { devices, group }) {
      await dispatch("createJob", {
        items: devices,
        jobName: `${group.name}: Remove ${devices.length} Member${
          devices.length == 1 ? "" : "s"
        }`,
        nameProp: "displayName",
      }).then((job) => {
        group.job = job;
      });
      await dispatch("modifyMembership", {
        members: devices,
        group: group,
        method: mcmapi.RemoveDeviceFromBusinessRole,
        job: group.job,
      });
    },
    async deleteDevices({ dispatch }, { devices, deleteFrom }) {
      // Delete from Intune //
      if (deleteFrom.value & 1) {
        //await dispatch("deleteDevicesFromIntune", { devices, group: { name: "Intune" } });
      }
      // Delete from Autopilot //
      if (deleteFrom.value & 2) {
        //await dispatch("deleteDevicesFromAutopilot", { devices, group: { name: "Autopilot" } });
      }
      // Delete from Azure AD //
      if (deleteFrom.value & 4) {
        await dispatch("deleteDevicesFromAzureAD", {
          devices,
          group: { name: "Azure AD" },
        });
      }
    },
    async deleteDevicesFromIntune({ commit, dispatch }, { devices, group }) {
      await dispatch("createJob", {
        items: devices,
        jobName: `${group.name}: Delete ${devices.length} Device${
          devices.length == 1 ? "" : "s"
        }`,
        nameProp: "displayName",
      }).then((job) => {
        group.job = job;
      });
      await asyncForEach(devices, async (device, index) => {
        await graph
          .deleteIntuneDevice(device)
          .then(() => {
            group.job.steps[index].status = "success";
          })
          .catch((err) => {
            group.job.steps[index].error = err.title;
            group.job.steps[index].status = "error";
            group.job.errorCount++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "errorCount",
              value: group.job.errorCount,
            });
          })
          .finally(() => {
            group.job.completedSteps++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "completedSteps",
              value: group.job.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "steps",
              value: group.job.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: group.job.id,
        property: "status",
        value: "finished",
      });
    },
    async deleteDevicesFromAutopilot({ commit, dispatch }, { devices, group }) {
      await dispatch("createJob", {
        items: devices,
        jobName: `${group.name}: Delete ${devices.length} Device${
          devices.length == 1 ? "" : "s"
        }`,
        nameProp: "displayName",
      }).then((job) => {
        group.job = job;
      });
      await asyncForEach(devices, async (device, index) => {
        await graph
          .deleteAutopilotDevice(device)
          .then(() => {
            group.job.steps[index].status = "success";
          })
          .catch((err) => {
            group.job.steps[index].error = err.title;
            group.job.steps[index].status = "error";
            group.job.errorCount++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "errorCount",
              value: group.job.errorCount,
            });
          })
          .finally(() => {
            group.job.completedSteps++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "completedSteps",
              value: group.job.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "steps",
              value: group.job.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: group.job.id,
        property: "status",
        value: "finished",
      });
    },
    async deleteDevicesFromAzureAD({ commit, dispatch }, { devices, group }) {
      await dispatch("createJob", {
        items: devices,
        jobName: `${group.name}: Delete ${devices.length} Device${
          devices.length == 1 ? "" : "s"
        }`,
        nameProp: "displayName",
      }).then((job) => {
        group.job = job;
      });
      await asyncForEach(devices, async (device, index) => {
        await mcmapi
          .deleteAzureADDevice(device)
          .then(() => {
            group.job.steps[index].status = "success";
          })
          .catch((err) => {
            group.job.steps[index].error = err.title;
            group.job.steps[index].status = "error";
            group.job.errorCount++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "errorCount",
              value: group.job.errorCount,
            });
          })
          .finally(() => {
            group.job.completedSteps++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "completedSteps",
              value: group.job.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "steps",
              value: group.job.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: group.job.id,
        property: "status",
        value: "finished",
      });
    },
    async importAutopilotDevices({ commit, dispatch }, { devices, group }) {
      await dispatch("createJob", {
        items: devices,
        jobName: `${group.name}: Import ${devices.length} Autopilot Device${
          devices.length == 1 ? "" : "s"
        }`,
        nameprop: "SerialNumber",
      }).then((job) => {
        group.job = job;
      });
      await asyncForEach(devices, async (device, index) => {
        let autoPilotDevice = {
          "@odata.type":
            "#microsoft.graph.importedWindowsAutopilotDeviceIdentity",
          groupTag: `${group.name}W${device.FormFactor};${device.Tag}`,
          serialNumber: device.SerialNumber,
          productKey: "",
          hardwareIdentifier: device.HardwareHash,
          assignedUserPrincipalName: device.PrimaryUser,
          state: {
            "@odata.type":
              "microsoft.graph.importedWindowsAutopilotDeviceIdentityState",
            deviceImportStatus: "pending",
            deviceRegistrationId: "",
            deviceErrorCode: 0,
            deviceErrorName: "",
          },
        };
        await graph
          .importAutopilotDevice(autoPilotDevice)
          .then(() => {
            group.job.steps[index].status = "success";
          })
          .catch((err) => {
            group.job.steps[index].error = err.title;
            group.job.steps[index].status = "error";
            group.job.errorCount++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "errorCount",
              value: group.job.errorCount,
            });
          })
          .finally(() => {
            group.job.completedSteps++;
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "completedSteps",
              value: group.job.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: group.job.id,
              property: "steps",
              value: group.job.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: group.job.id,
        property: "status",
        value: "finished",
      });
    },
    async triggerDeviceActions({ commit, dispatch }, { devices, action }) {
      let jobObject = null;
      let allowedActions = {
        reboot: "rebootNow",
        sync: "syncDevice",
      };
      if (!allowedActions[action]) return;

      await dispatch("createJob", {
        items: devices,
        jobName: `Trigger ${action} on ${devices.length} Device${
          devices.length == 1 ? "" : "s"
        }`,
        nameProp: "deviceName",
      }).then((job) => {
        jobObject = job;
      });
      await asyncForEach(devices, async (device, index) => {
        await graph
          .triggerDeviceAction(device.id, allowedActions[action])
          .then(() => {
            jobObject.steps[index].status = "success";
          })
          .catch((err) => {
            jobObject.steps[index].error = err.title ? err.title : err;
            jobObject.steps[index].status = "error";
            jobObject.errorCount++;
            commit("updateJobFromJobList", {
              id: jobObject.id,
              property: "errorCount",
              value: jobObject.errorCount,
            });
          })
          .finally(() => {
            jobObject.completedSteps++;
            commit("updateJobFromJobList", {
              id: jobObject.id,
              property: "completedSteps",
              value: jobObject.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: jobObject.id,
              property: "steps",
              value: jobObject.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: jobObject.id,
        property: "status",
        value: "finished",
      });
    },

    async addUsersToBusinessRole({ dispatch }, { users, group }) {
      await dispatch("createJob", {
        items: users,
        jobName: `${group.name}: Add ${users.length} Member${
          users.length == 1 ? "" : "s"
        }`,
        nameProp: "displayName",
      }).then((job) => {
        group.job = job;
      });
      await dispatch("modifyMembership", {
        members: users,
        group: group,
        method: mcmapi.AddUserToBusinessRole,
        job: group.job,
      });
    },
    async removeUsersFromBusinessRole({ dispatch }, { users, group }) {
      await dispatch("createJob", {
        items: users,
        group: group,
        jobName: `${group.name}: Remove ${users.length} Member${
          users.length == 1 ? "" : "s"
        }`,
        nameProp: "displayName",
      }).then((job) => {
        group.job = job;
      });
      await dispatch("modifyMembership", {
        members: users,
        group: group,
        method: mcmapi.RemoveUserFromBusinessRole,
        job: group.job,
      });
    },
    async moveObjectsToRole(
      { dispatch },
      { objects, currentGroup, targetGroup }
    ) {
      if (currentGroup.scope === "Devices") {
        await dispatch("addDevicesToBusinessRole", {
          devices: objects,
          group: targetGroup,
        });
        await dispatch("removeDevicesFromBusinessRole", {
          devices: objects,
          group: currentGroup,
        });
      } else if (currentGroup.scope === "Users") {
        await dispatch("addUsersToBusinessRole", {
          users: objects,
          group: targetGroup,
        });
        await dispatch("removeUsersFromBusinessRole", {
          users: objects,
          group: currentGroup,
        });
      }
    },
    async addRolesToApplicationGroups(
      { dispatch },
      { roles, apps, assignmentType }
    ) {
      await asyncForEach(apps, async (app) => {
        let jobObject = null;
        await dispatch("createJob", {
          items: roles,
          jobName: `${app.displayName}: Assign ${roles.length} Role${
            roles.length == 1 ? "" : "s"
          } (${assignmentType})`,
        }).then((job) => {
          jobObject = job;
        });
        await dispatch("modifyMembership", {
          members: roles,
          group: { id: app.groupId },
          method: mcmapi.addRoleToAssignmentGroup,
          job: jobObject,
        });
      });
    },
    async addRolesToAssignmentGroups({ dispatch }, { roles, groups }) {
      await asyncForEach(groups, async (group) => {
        await dispatch("createJob", {
          items: roles,
          jobName: `${group.shortName}: Add ${roles.length} Member${
            roles.length == 1 ? "" : "s"
          }`,
        }).then((job) => {
          group.job = job;
        });
      });
      await asyncForEach(groups, async (group) => {
        await dispatch("modifyMembership", {
          members: roles,
          group: group,
          method: mcmapi.addRoleToAssignmentGroup,
          job: group.job,
        });
      });
    },
    async removeRolesFromAssignmentGroups({ dispatch }, { roles, groups }) {
      await asyncForEach(groups, async (group) => {
        await dispatch("createJob", {
          items: roles,
          jobName: `${group.shortName}: Remove ${roles.length} Member${
            roles.length == 1 ? "" : "s"
          }`,
        }).then((job) => {
          group.job = job;
        });
      });
      await asyncForEach(groups, async (group) => {
        await dispatch("modifyMembership", {
          members: roles,
          group: group,
          method: mcmapi.removeRoleFromAssignmentGroup,
          job: group.job,
        });
      });
    },
    /*
    async createJob({ commit }, { members, group, jobName }) {
      let steps = members.map((m) => ({
        member: m.shortName ? m.shortName : m.displayName ? m.displayName : m,
        target: group.shortName ? group.shortName : group.displayName ? group.displayName : group.name,
        status: "open",
        error: null,
      }));
      let job = {
        id: createUUID(),
        name: jobName,
        time: new Date(),
        status: "running",
        steps: steps,
        totalSteps: members.length,
        completedSteps: 0,
        errorCount: 0,
      };
      commit("addJobtoJobList", job);
      return job;
    },
*/
    async createJob(
      { commit },
      { items, target = null, jobName, nameProp = null }
    ) {
      let steps = items.map((m) => ({
        member: nameProp ? m[nameProp] : m,
        target: target,
        status: "open",
        error: null,
      }));
      let job = {
        id: createUUID(),
        name: jobName,
        time: new Date(),
        status: "running",
        steps: steps,
        totalSteps: items.length,
        completedSteps: 0,
        errorCount: 0,
      };
      commit("addJobtoJobList", job);
      return job;
    },
    async modifyMembership({ commit }, { members, group, method, job }) {
      await asyncForEach(members, async (member, index) => {
        await method(member, group.id)
          .then(() => {
            job.steps[index].status = "success";
          })
          .catch((err) => {
            job.steps[index].error = err.title;
            job.steps[index].status = "error";
            job.errorCount++;
            commit("updateJobFromJobList", {
              id: job.id,
              property: "errorCount",
              value: job.errorCount,
            });
          })
          .finally(() => {
            job.completedSteps++;

            commit("updateJobFromJobList", {
              id: job.id,
              property: "completedSteps",
              value: job.completedSteps,
            });
            commit("updateJobFromJobList", {
              id: job.id,
              property: "steps",
              value: job.steps,
            });
          });
      });
      commit("updateJobFromJobList", {
        id: job.id,
        property: "status",
        value: "finished",
      });
    },
    removeFinishedJobs({ commit, state }) {
      commit(
        "setJobList",
        state.jobList.filter((x) => x.status != "finished")
      );
    },
  },
  modules: {},
});
