<template>
  <TSidePanel
    showSearch
    :search.sync="search"
    hideSearchDetails
    v-on:close="$emit('close')"
  >
    <template v-slot:title> Add Devices </template>

    <template v-slot:top-title>
      <div class="d-flex justify-space-between my-n3">
        <v-btn-toggle
          v-model="deviceType"
          group
          color="primary"
          borderless
          dense
        >
          <v-btn text class="text-capitalize">Enrolled Devices</v-btn>

          <v-btn text class="text-capitalize">Autopilot Devices</v-btn>
        </v-btn-toggle>
      </div>
    </template>

    <template v-slot:top-content>
      <v-switch
        v-model="showAllDevices"
        inset
        class="ml-2 mt-2"
        label="Show All Devices"
      ></v-switch>
      <v-data-table
        v-show="deviceType === 0"
        :items="devicesToShow"
        :headers="enrolledheaders"
        :show-select="!jobCreated"
        dense
        v-model="selectedItems"
        :loading="getEnrolledDevicesIsLoading"
        :loader-height="2"
        :search="search"
        sort-by="displayName"
        :items-per-page="-1"
        hide-default-footer
      >
      </v-data-table>

      <v-data-table
        v-show="deviceType === 1"
        :items="devicesToShow"
        :headers="autopilotHeaders"
        :show-select="!jobCreated"
        dense
        v-model="selectedItems"
        :loading="getAutopilotDevicesIsLoading"
        :loader-height="2"
        :search="search"
        sort-by="displayName"
        :items-per-page="-1"
        hide-default-footer
      >
      </v-data-table>
    </template>

    <template v-slot:bottom-title>
      Selected Devices ({{ selectedItems.length }})
    </template>

    <template v-slot:bottom-content>
      <v-list
        v-if="selectedItems.length"
        color="transparent"
        dense
        class="pt-0"
      >
        <v-list-item
          v-for="device in selectedItems"
          :key="device.id"
          class="pl-0 ma-0"
        >
          <v-list-item-icon><v-icon>mdi-laptop</v-icon></v-list-item-icon>
          <span>
            {{ device.displayName }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!selectedItems.length"
        @click="addDevicesToRole"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add Devices
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      enrolledDevices: [],
      autopilotDevices: [],
      enrolledheaders: [{ text: "Name", value: "displayName" }],
      autopilotHeaders: [
        { text: "Name", value: "displayName" },
        { text: "location", value: "city" },
        { text: "Tag", value: "identifyTags" },
      ],
      search: null,
      selectedItems: [],
      deviceType: 0,
      getEnrolledDevicesIsLoading: false,
      getAutopilotDevicesIsLoading: false,
      jobCreated: false,
      showAllDevices: false,
    };
  },
  methods: {
    async getCountryIdByName(scope, countryName) {
      return await mcmapi
        .GetCountryByName(scope, countryName)
        .then((resp) => (resp.values.length === 1 ? resp.values[0].id : null))
        .catch(() => null);
    },
    async getEnrolledDevices(countryId) {
      if (!countryId) return;
      this.items = [];
      this.getEnrolledDevicesIsLoading = true;
      await mcmapi
        .GetAllDevicesOfCountry(countryId, 0)
        .then((response) => {
          this.enrolledDevices = response;
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.getEnrolledDevicesIsLoading = false;
        });
    },
    async getAutopilotDevices(countryId) {
      if (!countryId) return;
      this.items = [];
      this.getAutopilotDevicesIsLoading = true;
      await mcmapi
        .GetAllDevicesOfCountry(countryId, 1)
        .then((response) => {
          this.autopilotDevices = response.filter(
            (x) => !this.enrolledDevices.map((y) => y.id).includes(x.id)
          );
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.getAutopilotDevicesIsLoading = false;
        });
    },
    closeDialog() {
      this.$emit("close");
    },
    addDevicesToRole() {
      this.$store.dispatch("addDevicesToBusinessRole", {
        devices: this.selectedItems,
        group: this.group,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
  computed: {
    deviceCount() {
      return this.selectedItems.length;
    },
    devicesToShow() {
      return this.deviceType == 0
        ? this.enrolledDevices.filter((x) =>
            this.showAllDevices
              ? x
              : x.displayName.startsWith(this.group.path[1])
          )
        : this.autopilotDevices.filter((x) =>
            this.showAllDevices ? x : x.city == this.group.path[1]
          );
    },
  },
  watch: {
    id() {
      this.getEnrolledDevices();
    },
  },
  mounted() {
    if (this.group.path.length > 0) {
      this.getCountryIdByName("Devices", this.group.path[0]).then(
        (countryId) => {
          this.getEnrolledDevices(countryId);
          this.getAutopilotDevices(countryId);
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
