<template>
  <div>
    <v-row no-gutters class="py-3" justify="space-between">
      <v-col>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'addApplicationToRole',
              group.type,
              permission
            )
          "
          text
          class="font-weight-light text-capitalize"
          @click="showAddApplicationDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>

          <span v-if="$vuetify.breakpoint.lgAndUp">Add Applications</span>
        </v-btn>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'removeApplicationFromRole',
              group.type,
              permission
            )
          "
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showRemoveApplicationDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-minus</v-icon>

          <span v-if="$vuetify.breakpoint.lgAndUp">Remove Applications</span>
        </v-btn>
        <v-btn
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showCopyApplicationDialog = true"
        >
          <v-icon size="20" color="primary" class="mr-1"
            >mdi-content-copy</v-icon
          >

          <span v-if="$vuetify.breakpoint.lgAndUp">Copy Applications</span>
        </v-btn>
        <v-btn
          text
          class="font-weight-light text-capitalize"
          @click="getApplications()"
          :disabled="isLoading"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
        </v-btn>
      </v-col>
      <v-col cols="3" md="3" xl="2">
        <v-responsive width="auto" min-width="120">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            hide-details
            outlined
            rounded
          ></v-text-field>
        </v-responsive>
      </v-col>
    </v-row>

    <v-layout column style="height: calc(100vh - 190px)">
      <v-flex style="overflow: auto">
        <v-data-table
          :items="items"
          @toggle-select-all="selectAllToggle"
          :headers="headers"
          v-model="selectedItems"
          dense
          show-select
          :search="search"
          :loading="isLoading"
          :loader-height="2"
          loading-text="Loading Applications..."
          :items-per-page="-1"
          no-data-text="No Applications assigned"
          sort-by="shortName"
        >
          <template v-slot:item.installType="{ item }">
            <span> {{ formatCamelCaseToDisplayName(item.installType) }} </span>
          </template>
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
              v-if="item.membership === 'Direct'"
            ></v-simple-checkbox>
            <v-icon v-else color="grey lighten-2"
              >mdi-checkbox-blank-off-outline</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      v-model="showAddApplicationDialog"
      absolute
      right
      width="700"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <AddApplications
        v-if="showAddApplicationDialog"
        :group="group"
        :selectedRoles="$store.getters.selectedRoles($route.name)"
        v-on:close="
          showAddApplicationDialog = false;
          getApplications();
        "
      ></AddApplications>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showRemoveApplicationDialog"
      absolute
      right
      width="600"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <RemoveApplications
        v-if="showRemoveApplicationDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="
          showRemoveApplicationDialog = false;
          getApplications();
        "
      ></RemoveApplications>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showCopyApplicationDialog"
      absolute
      right
      width="600"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <CopyApplications
        v-if="showCopyApplicationDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="showCopyApplicationDialog = false"
      ></CopyApplications>
    </v-navigation-drawer>
  </div>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import AddApplications from "@/components/Applications/AddApplications";
import RemoveApplications from "@/components/Applications/RemoveApplications";
import CopyApplications from "@/components/Applications/CopyApplications";

export default {
  components: {
    AddApplications,
    RemoveApplications,
    CopyApplications,
  },
  props: {
    group: {
      type: Object,
    },
    transitive: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
      default: "None",
    },
  },
  data() {
    return {
      items: [],
      inheritedItems: [],
      headers: [
        { text: "Name", value: "shortName" },
        { text: "Install Type", value: "installType" },
        { text: "Membership", value: "membership" },
      ],
      isLoading: false,
      search: null,
      selectedItems: [],
      showAddApplicationDialog: false,
      showRemoveApplicationDialog: false,
      showCopyApplicationDialog: false,
    };
  },
  methods: {
    async getApplications() {
      if (!this.group.id) return;
      this.items = [];
      this.isLoading = true;
      await mcmapi
        .GetMemberOf({ id: this.group.id, transitive: false })
        .then((response) => {
          this.items = response
            .filter(
              (x) => x.type == "group" && x.displayName.startsWith("MCM_SWD")
            )
            .map((obj) => {
              let temp = obj.displayName.replace("MCM_SWD_", "").split("_");
              return {
                ...obj,
                installType: temp.pop(),
                shortName: temp.join("_"),
                membership: "Direct",
              };
            });
        })
        .catch((err) => console.warn(err));
      await mcmapi
        .GetMemberOf({ id: this.group.id, transitive: true })
        .then((response) => {
          this.items = this.items.concat(
            response
              .filter(
                (x) =>
                  x.type == "group" &&
                  x.displayName.startsWith("MCM_SWD") &&
                  !this.items.some((y) => y.id === x.id)
              )
              .map((obj) => {
                let temp = obj.displayName.replace("MCM_SWD_", "").split("_");
                return {
                  ...obj,
                  installType: temp.pop(),
                  shortName: temp.join("_"),
                  membership: "Inherited",
                };
              })
          );
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
          this.selectedItems = [];
        });
    },
    selectAllToggle(props) {
      let availableItems = props.items.filter(
        (item) => item.membership === "Direct"
      );
      if (this.selectedItems.length !== availableItems.length) {
        this.selectedItems = availableItems;
      } else {
        this.selectedItems = [];
      }
    },
  },
  computed: {
    ApplicationCount() {
      return this.items.length;
    },
  },
  mounted() {
    if (this.group) this.getApplications();
  },
};
</script>
