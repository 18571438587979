<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="10">
        <v-card outlined color="grey lighten-4">
          <v-card-title>{{ job.name }}</v-card-title>
          <v-card-text v-if="job">
            <v-layout column style="height: calc(100vh - 190px)">
              <v-flex style="overflow: auto">
                <v-list two-line disabled color="transparent">
                  <v-list-item-group>
                    <template v-for="(step, index) in job.steps">
                      <v-list-item :key="index">
                        <template>
                          <v-list-item-icon class="my-auto text-center">
                            <v-icon
                              size="30"
                              v-if="step.status == 'success'"
                              color="success"
                            >
                              mdi-check-circle
                            </v-icon>
                            <v-icon
                              size="30"
                              v-if="step.status == 'error'"
                              color="error"
                            >
                              mdi-alert-circle
                            </v-icon>
                            <v-icon
                              size="30"
                              v-if="step.status == 'open'"
                              color="grey"
                            >
                              mdi-circle-outline
                            </v-icon>
                            <v-progress-circular
                              class="ml-1"
                              size="22"
                              color="primary"
                              width="2"
                              v-if="step.status == 'running'"
                              indeterminate
                            ></v-progress-circular>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              ><span class="font-weight-medium"
                                >{{ step.target }}: </span
                              ><span>
                                {{ step.member }}</span
                              ></v-list-item-title
                            >
                            <div v-if="typeof step.error === 'string'">
                              <span class="caption error--text">
                                {{ step.error }}
                              </span>
                            </div>
                            <div v-else>
                              <div v-if="step.error">
                                <span
                                  v-for="(error, index) in step.error"
                                  :key="index"
                                  class="caption error--text"
                                >
                                  {{ Array.isArray(error) ? error[0] : error }}
                                </span>
                              </div>
                            </div>
                          </v-list-item-content>
                        </template>
                      </v-list-item>

                      <v-divider
                        v-if="index < job.steps.length - 1"
                        :key="'d' + index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>This job had no errors!</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    getTimespan(eventTime) {
      let diff = new Date() - eventTime;
      let seconds = diff / 1000;
      if (seconds < 60) return Math.round(seconds, 0) + "s";
      let minutes = seconds / 60;
      if (minutes < 60) return Math.round(minutes, 0) + "m";
      let hours = minutes / 60;
      if (hours < 24) return Math.round(hours, 0) + "h";
      let days = hours / 24;
      return Math.round(days, 0) + "d";
    },
  },
  computed: {
    job() {
      return (
        this.$store.state.jobList.filter(
          (x) => x.id == this.$route.params.jobId
        )[0] ?? []
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
