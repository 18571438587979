<template>
  <TSidePanel show-search :search.sync="search" v-on:close="$emit('close')">
    <template v-slot:title> Delete {{ roleTypeToDelete }}</template>

    <template v-slot:top-title>Available {{ roleTypeToDelete }}</template>

    <template v-slot:top-content>
      <v-data-table
        :items="items"
        :headers="[
          {
            text: roleTypeToDelete === 'Cities' ? 'Location' : 'Business',
            value: 'name',
          },
        ]"
        :show-select="!jobCreated"
        dense
        v-model="selectedItems"
        :loader-height="2"
        :search="search"
        sort-by="name"
        :items-per-page="-1"
        hide-default-footer
      >
      </v-data-table>
    </template>

    <template v-slot:bottom-title>
      Selected {{ roleTypeToDelete }} ({{ selectedItems.length }})
    </template>

    <template v-slot:bottom-content>
      <v-alert dense border="left" type="info" prominent
        ><span v-if="roleTypeToDelete == 'Cities'"
          >Only Cities without attached Businesses can be deleted!</span
        >
        <span v-else>Only Businesses without members can be deleted!</span>
      </v-alert>
      <v-list
        v-if="selectedItems.length"
        color="transparent"
        dense
        class="pt-0"
      >
        <v-list-item
          v-for="role in selectedItems"
          :key="role.id"
          class="pl-0 ma-0"
        >
          <v-list-item-icon
            ><v-icon>{{
              $store.getters.groupTypeIcon(role.mcmType.split("_")[2])
            }}</v-icon></v-list-item-icon
          >
          <span>
            {{ role.name }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        :disabled="!selectedItems.length"
        color="primary"
        @click="deleteRoles"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Delete {{ roleTypeToDelete }}
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      jobCreated: false,
      selectedItems: [],
      search: null,
      items: [],
    };
  },
  computed: {
    roleTypeToDelete() {
      return this.group.type == "CountryRole" ? "Cities" : "Businesses";
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    async getChildren() {
      const method =
        this.roleTypeToDelete === "Cities"
          ? mcmapi.GetCitiesByCountryId
          : mcmapi.GetBusinessesByCityId;
      await method(this.group.id)
        .then((response) => (this.items = response))
        .catch(() => null);
    },
    deleteRoles() {
      this.$store.dispatch("deleteRoles", {
        parent: this.group,
        groups: this.selectedItems,
        roleTypeToDelete: this.roleTypeToDelete,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
  mounted() {
    this.getChildren();
  },
};
</script>
