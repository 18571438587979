<template>
  <v-card elevation="0" v-if="appInfos">
    <v-card-title v-if="embedded" class="pb-0 pl-5 pt-1">
      <v-avatar class="mr-3" tile>
        <v-img
          v-if="appInfos.largeIcon"
          :src="'data:image/png;base64,' + appInfos.largeIcon.value"
          height="42"
          width="42"
          contain
        ></v-img>
        <v-icon v-else large color="orange darken-2">mdi-apps</v-icon>
      </v-avatar>
      <span :style="'color: ' + avatarColor">{{ appInfos.displayName }}</span>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            text
            :to="`/applicationdetails/${appInfos.id}`"
            ><v-icon color="grey">mdi-open-in-new</v-icon></v-btn
          >
        </template>
        <span>Full Screen View</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pa-1">
      <v-row no-gutters class="py-2" justify="space-between">
        <v-col class="mb-0">
          <v-btn
            text
            class="font-weight-light text-capitalize"
            @click="getData()"
          >
            <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
          </v-btn>
          <v-btn
            text
            class="font-weight-light text-capitalize"
            :href="`https://endpoint.microsoft.com/#view/Microsoft_Intune_Apps/SettingsMenu/~/0/appId/${appInfos.id}`"
            target="New"
            ><v-icon size="25" color="primary" class="mr-1"
              >mdi-microsoft-azure</v-icon
            >Open in MEM</v-btn
          >
        </v-col>
      </v-row>

      <v-layout column style="height: calc(100vh - 195px)">
        <v-flex style="overflow-y: auto; overflow-x: hidden">
          <v-card class="ma-4 mt-0" outlined>
            <v-card-title> Description </v-card-title>
            <v-card-text>
              {{ appInfos.description }}
            </v-card-text>
          </v-card>
          <v-card class="ma-4 mt-0" outlined>
            <v-card-title>
              Details
              <v-btn
                text
                color="grey"
                class="text-capitalize"
                small
                @click="showAllDetails = !showAllDetails"
              >
                <v-icon>{{
                  showAllDetails ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Show {{ showAllDetails ? "Basic" : "Extended" }} Application
                Details</v-btn
              >
            </v-card-title>
            <v-card-text v-if="!showAllDetails">
              <v-row
                v-for="prop in basicappInfosProps"
                :key="prop.value"
                class="font-weight-bold"
              >
                <v-col :cols="embedded ? 4 : 2" class="pa-0 pl-4">
                  <span class="caption">{{ prop.text }}</span>
                </v-col>
                <v-col :cols="embedded ? 8 : 4" class="pa-0">
                  <span class="text-capitalize">{{
                    prop.transform
                      ? prop.transform(appInfos[prop.value])
                      : appInfos[prop.value]
                  }}</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="showAllDetails">
              <v-row
                v-for="(value, key) in appInfos"
                :key="key"
                class="font-weight-bold"
              >
                <v-col :cols="embedded ? 6 : 3" class="pa-0 pl-4">
                  <span class="caption">{{ key }}</span>
                </v-col>
                <v-col :cols="embedded ? 6 : 3" class="pa-0">
                  <span class="text-capitalize">{{ value }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="ma-4 mt-0" outlined v-if="appDependencies.length">
            <v-card-title> Dependencies </v-card-title>
            <v-card-text>
              <v-row
                v-for="app in appDependencies"
                :key="app.id"
                class="font-weight-bold"
              >
                <v-col cols="8" class="pa-0 pl-4">
                  <span>{{ app.targetDisplayName }}</span>
                </v-col>
                <v-col cols="4" class="pa-0">
                  <span class="text-capitalize caption">{{
                    app.dependencyType
                  }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>

  <v-card v-else elevation="0">
    <v-card-text v-if="!appInfos && !isLoading" class="text-center mt-12"
      ><v-icon color="grey lighten-1" size="160"
        >mdi-emoticon-cry-outline</v-icon
      >
      <p class="mt-2 mb-0 text-overline">
        Sorry, the application you are looking for does not exist...
      </p>
    </v-card-text>
    <v-card-text v-if="!appInfos && isLoading" class="text-center mt-12">
      <v-skeleton-loader type="heading, table"></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import graph from "@/Services/graph";
import * as Vibrant from "node-vibrant";

export default {
  components: {},
  props: {
    appId: {
      type: String,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appInfos: null,
      showAllDetails: false,
      isLoading: false,
      basicappInfosProps: [
        { text: "Version", value: "displayVersion" },
        { text: "Publisher", value: "publisher" },
        {
          text: "Creation Date",
          value: "createdDateTime",
          transform: this.formatDate,
        },
        { text: "Size", value: "size", transform: this.formatSize },
        { text: "Owner", value: "owner" },
      ],
      avatarColor: "#333333",
      appDependencies: [],
    };
  },
  methods: {
    async getApplicationDetails() {
      this.isLoading = true;
      this.appInfos = null;
      if (this.appId) {
        const result = await graph.getApplicationDetails(this.appId);
        if (result) {
          this.appInfos = result;
          this.$store.commit(
            "setCurrentActiveObjectName",
            this.appInfos.displayName
          );
          if (this.appInfos.largeIcon) this.getColors();
        } else this.appInfos = null;
      }
      this.isLoading = false;
    },
    async getApplicationDependencies() {
      this.isLoading = true;
      this.appDependencies = null;
      if (this.appId) {
        const result = await graph.getApplicationDependencies(this.appId);
        if (result.value) {
          this.appDependencies = result.value;
        }
      }
      this.isLoading = false;
    },
    getColors() {
      let v = new Vibrant(
        "data:image/png;base64," + this.appInfos.largeIcon.value
      );
      v.getPalette(
        (err, palette) => (this.avatarColor = palette.DarkVibrant.getHex())
      );
    },
    getData() {
      this.getApplicationDetails();
      this.getApplicationDependencies();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped></style>
