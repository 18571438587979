export const GROUP_TYPE_ICONS = {
  CountryRole: "mdi-map-marker-outline",
  CityRole: "mdi-city-variant-outline",
  BusinessRole: "mdi-handshake-outline",
};

export const PERMISSION_MATRIX = {
  addDeviceToRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  removeDeviceFromRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  importAutopilotDevices: {
    Operator: {
      CountryRole: false,
      CityRole: true,
      BusinessRole: false,
    },
    Admin: {
      CountryRole: false,
      CityRole: true,
      BusinessRole: false,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  deleteDevice: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  addUserToRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  removeUserFromRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  addApplicationToRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: true,
      CityRole: true,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  removeApplicationFromRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: true,
      CityRole: true,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  addConfigurationProfileToRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: true,
      CityRole: true,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  removeConfigurationProfileFromRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: true,
    },
    Admin: {
      CountryRole: true,
      CityRole: true,
      BusinessRole: true,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  createCityRole: {
    Operator: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
    Admin: {
      CountryRole: true,
      CityRole: false,
      BusinessRole: false,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  createBusinessRole: {
    Operator: {
      CountryRole: false,
      CityRole: true,
      BusinessRole: false,
    },
    Admin: {
      CountryRole: false,
      CityRole: true,
      BusinessRole: false,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
  deleteRole: {
    Operator: {
      CountryRole: false,
      CityRole: true,
      BusinessRole: false,
    },
    Admin: {
      CountryRole: true,
      CityRole: true,
      BusinessRole: false,
    },
    None: {
      CountryRole: false,
      CityRole: false,
      BusinessRole: false,
    },
  },
};
