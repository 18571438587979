<template>
  <TSidePanel v-on:close="$emit('close')">
    <template v-slot:title> Import Autopilot Devices</template>

    <template v-slot:top-title
      ><v-btn color="primary" small href="/AutopilotTemplate.xlsx"
        ><v-icon>mdi-download</v-icon>Download Excel Template</v-btn
      >
    </template>

    <template v-slot:top-content>
      <div>
        <FreeTextBox
          v-on:validation-passed="checkData($event)"
          v-model="textBoxData"
          placeholder="Tag / Identifier,Primary User, Serial Number, Form Factor, Hardware Hash"
          :columns="textBoxColumns"
          :rows="6"
        ></FreeTextBox>
      </div>
    </template>

    <template v-slot:bottom-title>
      Devices ({{ selectedItems.length }})
    </template>

    <template v-slot:bottom-content>
      <v-alert
        v-if="userCheckProgress > 0"
        dense
        prominent
        :type="alertType"
        text
        outlined
        class="pa-2"
      >
        <v-list dense class="pa-0">
          <v-list-item dense>
            <v-list-item-title class="subtitle-2">
              {{ userCheckProgress }} Primary Users checked
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="invalidUsers.length > 0" dense>
            <v-list-item-title>
              <span class="error--text">
                {{ invalidUsers.length }} User{{
                  invalidUsers.length == 1 ? "" : "s"
                }}
                not found
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(user, index) in invalidUsers" :key="index">
            <v-list-item-content class="caption">
              {{ user }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-simple-table v-if="selectedItems.length" dense>
        <thead>
          <tr>
            <th>Tag / Identifier</th>
            <th>Form Factor</th>
            <th>Primary User</th>
            <th>Serial Number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="device in selectedItems" :key="device.SerialNumber">
            <td>{{ device.Tag }}</td>
            <td>{{ device.FormFactor }}</td>
            <td>{{ device.PrimaryUser }}</td>
            <td>{{ device.SerialNumber }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!selectedItems.length || !!invalidUsers.length"
        @click="importAutopilotDevices"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Import Devices
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
//import mcmapi from "@/Services/mcm-api";
import graph from "@/Services/graph";
import TSidePanel from "@/components/Templates/TSidePanel";
import FreeTextBox from "@/components/Templates/FreeTextBox";

export default {
  components: {
    TSidePanel,
    FreeTextBox,
  },
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      items: [],
      headers: [{ text: "Name", value: "displayName" }],
      selectedItems: [],
      userType: 0,
      isLoading: false,
      jobCreated: false,
      searchTimer: false,
      message: "",
      textBoxData: null,
      userCheckProgress: 0,
      invalidUsers: [],
      textBoxColumns: [
        {
          name: "Tag",
          regex: null,
          unique: false,
          mandatory: false,
        },
        {
          name: "FormFactor",
          //prettier-ignore
          regex: /^N|D|V$/,
          unique: false,
          mandatory: true,
        },
        {
          name: "PrimaryUser",
          //prettier-ignore
          regex: /^\S+@majorel\.com$/,
          unique: false,
          mandatory: false,
        },
        {
          name: "SerialNumber",
          regex: null,
          unique: true,
          mandatory: true,
        },

        {
          name: "HardwareHash",
          regex: null,
          unique: true,
          mandatory: true,
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    checkData(data) {
      this.selectedItems = data;
      this.checkUsers(
        data.filter((y) => !!y.PrimaryUser).map((x) => x.PrimaryUser)
      );
    },
    async checkUsers(users) {
      async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
      }
      this.userCheckProgress = 0;
      this.validUsers = [];
      this.invalidUsers = [];
      await asyncForEach(users, async (user, index) => {
        this.userCheckProgress = index + 1;
        await graph
          .getUserByUPN(user)
          .then((response) => {
            if (response.value.length != 1) {
              this.invalidUsers.push(user);
            }
          })
          .catch(() => {
            this.invalidUsers.push(user);
          });
      });
      this.userCheckIsRunning = false;
    },
    importAutopilotDevices() {
      this.$store.dispatch("importAutopilotDevices", {
        devices: this.selectedItems,
        group: this.group,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
  computed: {
    userCount() {
      return this.selectedItems.length;
    },
    alertType() {
      return this.invalidUsers.length > 0 ? "error" : "success";
    },
  },
};
</script>
