<template>
  <div>
    <v-row no-gutters class="py-3" justify="space-between">
      <v-col>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'addDeviceToRole',
              group.type,
              permission
            )
          "
          text
          class="font-weight-light text-capitalize"
          @click="showAddDeviceDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Add</span>
        </v-btn>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'removeDeviceFromRole',
              group.type,
              permission
            )
          "
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showRemoveDeviceDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-minus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Remove</span>
        </v-btn>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'removeDeviceFromRole',
              group.type,
              permission
            )
          "
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showMoveDeviceDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1"
            >mdi-transfer-right</v-icon
          >
          <span v-if="$vuetify.breakpoint.lgAndUp">Move</span>
        </v-btn>
        <!--<v-btn
          v-if="$store.getters.userHasPermission('deleteDevice', group.type)"
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showDeleteDeviceDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-delete</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Delete</span>
        </v-btn>
        -->
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'importAutopilotDevices',
              group.type,
              permission
            ) && RegExp(/^[A-Z0-9]{7}$/).test(group.name)
          "
          text
          class="font-weight-light text-capitalize"
          @click="showImportAutopilotDeviceDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1"
            >mdi-server-plus</v-icon
          >
          <span v-if="$vuetify.breakpoint.lgAndUp"
            >Import Autopilot Devices</span
          >
        </v-btn>
        <v-btn
          text
          class="font-weight-light text-capitalize"
          @click="getDevices()"
          :disabled="isLoading"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
        </v-btn>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" class="pr-3">
        <v-chip class="pa-5 border" color="primary" outlined
          ><v-icon class="mr-2">mdi-laptop</v-icon>
          {{
            deviceCount + " " + (deviceCount == 1 ? "Device" : "Devices")
          }}</v-chip
        >
      </v-col>
      <v-col cols="3" md="3" xl="2">
        <v-responsive width="auto" min-width="120">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            hide-details
            outlined
            rounded
          ></v-text-field>
        </v-responsive>
      </v-col>
    </v-row>

    <v-layout column style="height: calc(100vh - 190px)">
      <v-flex style="overflow: auto">
        <v-data-table
          :items="items"
          :headers="headers"
          v-model="selectedItems"
          dense
          :show-select="group.type == 'BusinessRole'"
          :search="search"
          :loading="isLoading"
          :loader-height="2"
          loading-text="Loading Devices..."
          :items-per-page="15"
          no-data-text="No Devices assigned"
          sort-by="displayName"
        >
          <template v-slot:item.isAutopilot="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-if="item.isAutopilot"
                  v-bind="attrs"
                  v-on="on"
                  src="@/assets/autopilot.png"
                  contain
                  width="35"
                ></v-img>
              </template>
              <span>Autopilot Device</span>
            </v-tooltip>
          </template>
          <template v-slot:item.displayName="{ item }">
            <span class="pointer" @click="openDeviceDetailsDialog(item)">{{
              item.displayName
            }}</span>
          </template>
          <template v-slot:item.isCompliant="{ item }">
            <v-icon v-if="item.isCompliant" color="success"
              >mdi-check-circle</v-icon
            >
            <v-icon v-else color="error">mdi-alert-circle</v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      v-model="showAddDeviceDialog"
      absolute
      right
      width="600px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <AddDevices
        v-if="showAddDeviceDialog"
        :group="group"
        v-on:close="
          showAddDeviceDialog = false;
          getDevices();
        "
      ></AddDevices>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showRemoveDeviceDialog"
      absolute
      right
      width="500px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <RemoveDevices
        v-if="showRemoveDeviceDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="
          showRemoveDeviceDialog = false;
          getDevices();
        "
      ></RemoveDevices>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showMoveDeviceDialog"
      absolute
      right
      width="500px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <MoveDevices
        v-if="showMoveDeviceDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="
          showMoveDeviceDialog = false;
          getDevices();
        "
      ></MoveDevices>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showDeviceDetailsDialog"
      absolute
      right
      width="800"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <DeviceDetails
        :azureDevice="clickedDevice"
        v-if="showDeviceDetailsDialog"
        :embedded="true"
        v-on:close="showDeviceDetailsDialog = false"
      ></DeviceDetails>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showDeleteDeviceDialog"
      absolute
      right
      width="500px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <DeleteDevices
        v-if="showDeleteDeviceDialog"
        :items="selectedItems"
        v-on:close="
          showDeleteDeviceDialog = false;
          getDevices();
        "
      ></DeleteDevices>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showImportAutopilotDeviceDialog"
      absolute
      right
      width="800px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <ImportAutopilotDevices
        v-if="showImportAutopilotDeviceDialog"
        :group="group"
        v-on:close="showImportAutopilotDeviceDialog = false"
      ></ImportAutopilotDevices>
    </v-navigation-drawer>
  </div>
</template>

<script>
import mcmapi from "@/Services/mcm-api";
import AddDevices from "@/components/Devices/AddDevices";
import RemoveDevices from "@/components/Devices/RemoveDevices";
import DeviceDetails from "@/components/Devices/DeviceDetails";
import DeleteDevices from "@/components/Devices/DeleteDevices";
import MoveDevices from "@/components/Devices/MoveDevices";
import ImportAutopilotDevices from "@/components/Devices/ImportAutopilotDevices";

export default {
  components: {
    AddDevices,
    RemoveDevices,
    DeviceDetails,
    DeleteDevices,
    MoveDevices,
    ImportAutopilotDevices,
  },
  props: {
    group: {
      type: Object,
    },
    transitive: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
      default: "None",
    },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "", value: "isAutopilot", width: "25px" },
        { text: "Name", value: "displayName" },
        {
          text: "Compliance",
          value: "isCompliant",
          width: "120px",
          align: "center",
        },
        { text: "OS", value: "operatingSystem" },
        { text: "OS Build", value: "operatingSystemVersion" },
        { text: "Model", value: "model" },
        { text: "Manufacturer", value: "manufacturer" },
      ],
      isLoading: false,
      search: null,
      selectedItems: [],
      clickedDevice: null,
      showAddDeviceDialog: false,
      showRemoveDeviceDialog: false,
      showDeviceDetailsDialog: false,
      showDeleteDeviceDialog: false,
      showImportAutopilotDeviceDialog: false,
      showMoveDeviceDialog: false,
    };
  },
  methods: {
    async getDevices() {
      if (!this.group.id) return;
      this.items = [];
      this.selectedItems = [];
      this.isLoading = true;
      await mcmapi
        .GetMembers(this.group.id, this.transitive)
        .then((response) => {
          this.items = response.filter((x) => x["type"] == "device");
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
    openDeviceDetailsDialog(device) {
      this.clickedDevice = device;
      this.showDeviceDetailsDialog = true;
    },
  },
  computed: {
    deviceCount() {
      return this.items.length;
    },
  },
  mounted() {
    if (this.group) this.getDevices();
  },
};
</script>

<style></style>
