<template>
  <TSidePanel full-height v-on:close="$emit('close')">
    <template v-slot:title> Remove Users</template>

    <template v-slot:top-title> Selected Users ({{ items.length }})</template>

    <template v-slot:top-content>
      <v-list v-if="items.length" color="transparent" dense class="pt-0">
        <v-list-item v-for="user in items" :key="user.id" class="pl-0 ma-0">
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <span>
            {{ user.displayName }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!items.length"
        @click="removeUsersFromRole"
      >
        <v-icon class="mr-2">mdi-minus</v-icon>
        Remove Users
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import TSidePanel from "@/components/Templates/TSidePanel";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      jobCreated: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    removeUsersFromRole() {
      this.$store.dispatch("removeUsersFromBusinessRole", {
        users: this.items,
        group: this.group,
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
};
</script>
