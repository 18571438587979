<template>
  <v-card elevation="0" v-if="deviceInfos">
    <v-card-title v-if="embedded" class="pb-0 pl-5 pt-1">
      {{ deviceInfos.deviceName }}
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            text
            :to="`/devicedetails/${deviceInfos.azureADDeviceId}`"
            ><v-icon color="grey">mdi-open-in-new</v-icon></v-btn
          >
        </template>
        <span>Full View</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pa-1">
      <v-row no-gutters class="py-2" justify="space-between">
        <v-col class="mb-0">
          <v-btn
            text
            class="font-weight-light text-capitalize"
            @click="getManagedDeviceInfos()"
          >
            <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
          </v-btn>
          <v-btn
            text
            class="font-weight-light text-capitalize"
            @click="triggerDeviceSync()"
            ><v-icon size="25" color="primary" class="mr-1"
              >mdi-autorenew</v-icon
            >Sync</v-btn
          >
          <v-btn
            text
            class="font-weight-light text-capitalize"
            @click="showRebootDialog = !showRebootDialog"
            ><v-icon size="25" color="primary" class="mr-1">mdi-restart</v-icon
            >Reboot</v-btn
          >
          <!--
          <v-btn text class="font-weight-light text-capitalize"
            ><v-icon size="25" color="primary" class="mr-1">mdi-power</v-icon>Reset</v-btn
          >
          <v-btn text class="font-weight-light text-capitalize"
            ><v-icon size="25" color="primary" class="mr-1">mdi-delete</v-icon>Delete</v-btn
          >
          -->
          <v-btn
            text
            class="font-weight-light text-capitalize"
            :href="`https://endpoint.microsoft.com/#view/Microsoft_Intune_Devices/DeviceSettingsMenuBlade/~/overview/mdmDeviceId/${deviceInfos.id}`"
            target="New"
            ><v-icon size="25" color="primary" class="mr-1"
              >mdi-microsoft-azure</v-icon
            >Open in MEM</v-btn
          >
        </v-col>

        <v-col cols="12">
          <v-alert
            v-if="showRebootDialog"
            class="ml-4 mr-4 mt-0 mb-2"
            color="primary"
            icon="mdi-restart"
            dense
            prominent
            text
            mode=""
          >
            <p class="mb-2">Reboot device?</p>
            <v-btn
              color="primary"
              class="mr-2"
              outlined
              small
              @click="triggerDeviceReboot()"
              >Reboot</v-btn
            >
            <v-btn text light small @click="showRebootDialog = false"
              >Cancel</v-btn
            >
          </v-alert>
        </v-col>
      </v-row>

      <!--//* Device Details -->
      <v-layout column style="height: calc(100vh - 195px)">
        <v-flex style="overflow-y: auto; overflow-x: hidden">
          <v-card class="ma-4 mt-0" outlined>
            <v-card-title>
              Details
              <v-btn
                text
                color="grey"
                class="text-capitalize"
                small
                @click="showAllDetails = !showAllDetails"
              >
                <v-icon>{{
                  showAllDetails ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Show {{ showAllDetails ? "Basic" : "Extended" }} Device
                Details</v-btn
              >
            </v-card-title>
            <v-card-text v-if="!showAllDetails">
              <v-row
                v-for="prop in basicDeviceInfosProps"
                :key="prop.value"
                class="font-weight-bold"
              >
                <v-col :cols="embedded ? 4 : 2" class="pa-0 pl-4">
                  <span class="caption">{{ prop.text }}</span>
                </v-col>
                <v-col :cols="embedded ? 8 : 4" class="pa-0">
                  <span class="text-capitalize">{{
                    prop.transform
                      ? prop.transform(deviceInfos[prop.value])
                      : deviceInfos[prop.value]
                  }}</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="showAllDetails">
              <v-row
                v-for="(value, key) in deviceInfos"
                :key="key"
                class="font-weight-bold"
              >
                <v-col :cols="embedded ? 6 : 3" class="pa-0 pl-4">
                  <span class="caption">{{ key }}</span>
                </v-col>
                <v-col :cols="embedded ? 6 : 3" class="pa-0">
                  <span class="text-capitalize">{{ value }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!--//* Group Memberships -->
          <v-card
            class="ma-4"
            outlined
            :loader-height="2"
            :loading="groupMembershipsAreLoading"
          >
            <v-card-title
              >Group Memberships
              <v-btn
                text
                color="grey"
                class="text-capitalize"
                small
                @click="showAllGroupMemberships = !showAllGroupMemberships"
              >
                <v-icon>{{
                  showAllGroupMemberships
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}</v-icon>
                {{ showAllGroupMemberships ? "Hide" : "Show" }} All
                Memberships</v-btn
              ></v-card-title
            >
            <v-card-text>
              <v-row v-if="groupMemberships && !showAllGroupMemberships">
                <v-col cols="auto">
                  <v-list dense class="pa-0">
                    <v-list-item
                      v-for="(group, i) in groupMemberships.filter(
                        (x) =>
                          x.displayName?.startsWith('MCM_Devices') &&
                          x.displayName.split('_').length === 5
                      )"
                      :key="i"
                      class="subtitle-2 pl-1"
                    >
                      {{ group.displayName.split("_").slice(2).join(" | ") }}
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row
                v-if="groupMemberships && showAllGroupMemberships"
                class="pt-4"
              >
                <v-data-table
                  :items="groupMemberships"
                  :headers="[{ text: 'Group', value: 'displayName' }]"
                  dense
                  :sort-by="['displayName']"
                  :items-per-page="-1"
                  hide-default-footer
                >
                </v-data-table>
              </v-row>
              <v-row v-if="groupMembershipsError">
                <v-alert type="error" dense text prominent
                  >Failed to get Group Memberships. Do you have Azure AD read
                  access?
                  <p class="caption mb-0">
                    {{ groupMembershipsError }}
                  </p></v-alert
                >
              </v-row>
            </v-card-text>
          </v-card>

          <!--//* Primary User -->
          <v-card
            class="ma-4"
            outlined
            :disabled="!primaryUser"
            :to="primaryUser ? `/userdetails/${primaryUser.id}` : ''"
          >
            <v-card-title> Primary User </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="auto" class="pt-4 pb-0">
                  <v-avatar color="primary" size="55">
                    <v-icon color="white" v-if="primaryUser && !photo" large>
                      mdi-account
                    </v-icon>
                    <v-icon color="white" v-if="!primaryUser" large>
                      mdi-account-cancel-outline
                    </v-icon>
                    <img v-if="photo" :src="photo" alt="photo" />
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="text-left pt-3 pb-0">
                  <div v-if="primaryUser" class="text-h6 pt-1">
                    {{ primaryUser.displayName }}
                    <p class="text-caption mt-1">
                      {{ primaryUser.userPrincipalName }}
                    </p>
                  </div>
                  <div v-else class="text-h6 pt-1">No primary User</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!--//* Managed Apps -->
          <v-card
            class="ma-4"
            outlined
            :loader-height="2"
            :loading="managedAppsAreLoading"
          >
            <v-card-title
              >Managed Apps
              <v-btn
                text
                color="grey"
                class="text-capitalize"
                small
                @click="showAllManagedApps = !showAllManagedApps"
              >
                <v-icon>{{
                  showAllManagedApps ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Show {{ showAllManagedApps ? "Basic" : "Extended" }} Application
                Details</v-btn
              ></v-card-title
            >
            <v-card-text>
              <v-row v-if="managedApps && !showAllManagedApps">
                <v-col cols="auto" class="pt-4">
                  <v-avatar color="orange darken-2" size="55">
                    <span class="text-h5 white--text">
                      {{ managedApps.length }}</span
                    >
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="text-left pt-6">
                  <div class="text-h6 pt-1">Applications</div>
                </v-col>
              </v-row>
              <v-row v-if="managedApps && showAllManagedApps" class="pt-4">
                <v-data-table
                  :items="managedApps"
                  :headers="managedAppsHeader"
                  dense
                  :sort-by="['mobileAppIntent', 'displayName']"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:item.mobileAppIntent="{ item }">
                    <span class="text-capitalize">{{
                      item.mobileAppIntent.split(/(?=[A-Z])/).join(" ")
                    }}</span>
                  </template>
                  <template v-slot:item.installState="{ item }">
                    <span class="text-capitalize">{{
                      item.installState.split(/(?=[A-Z])/).join(" ")
                    }}</span>
                  </template>
                </v-data-table>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>

  <v-card v-else elevation="0">
    <v-card-text v-if="!deviceInfos && !isLoading" class="text-center mt-12"
      ><v-icon color="grey lighten-1" size="160"
        >mdi-emoticon-cry-outline</v-icon
      >
      <p class="mt-2 mb-0 text-overline">
        Sorry, the device you are looking for does not exist...
      </p>
    </v-card-text>
    <v-card-text v-if="!deviceInfos && isLoading" class="text-center mt-12">
      <v-skeleton-loader type="heading, table"></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import graph from "@/Services/graph";

export default {
  components: {},
  props: {
    azureDevice: {
      type: Object,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deviceInfos: null,
      showAllDetails: false,
      showAllManagedApps: false,
      showAllGroupMemberships: false,
      primaryUser: null,
      photo: null,
      managedApps: null,
      managedAppsAreLoading: false,
      groupMembershipsError: null,
      groupMemberships: null,
      groupMembershipsAreLoading: false,
      isLoading: false,
      basicDeviceInfosProps: [
        {
          text: "Last Sync Time",
          value: "lastSyncDateTime",
          transform: this.formatDate,
        },
        {
          text: "Enrollment Date",
          value: "enrolledDateTime",
          transform: this.formatDate,
        },
        { text: "Ownership", value: "managedDeviceOwnerType" },
        { text: "OS Version", value: "osVersion" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Model", value: "model" },
        { text: "Seriel Number", value: "serialNumber" },
      ],
      managedAppsHeader: [
        { text: "Name", value: "displayName" },
        { text: "Version", value: "displayVersion" },
        { text: "Intent", value: "mobileAppIntent" },
        { text: "Install State", value: "installState" },
      ],
      showRebootDialog: false,
    };
  },
  methods: {
    async getManagedDeviceInfos() {
      this.isLoading = true;
      this.deviceInfos = null;
      if (!this.azureDevice.deviceId) return;
      await graph
        .getManagedDeviceInfos(this.azureDevice.deviceId)
        .then((result) => {
          if (result.value.length == 1) {
            const obj = result.value[0];
            this.deviceInfos = Object.keys(obj)
              .sort()
              .reduce((accumulator, key) => {
                accumulator[key] = obj[key];

                return accumulator;
              }, {});
            this.$store.commit(
              "setCurrentActiveObjectName",
              this.deviceInfos.deviceName
            );
            this.getPrimaryUser(this.deviceInfos.id);
            this.getManagedDeviceManagedApps();
            this.getManagedDeviceGroupMemberships();
          } else this.deviceInfos = null;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getPrimaryUser(deviceId) {
      if (deviceId) {
        await graph.getPrimaryUserOfADevice(deviceId).then((response) => {
          this.primaryUser =
            response.value.length >= 1 ? response.value[0] : null;
          if (this.primaryUser) this.getPrimaryUsersPhoto(this.primaryUser.id);
        });
      }
    },
    async getPrimaryUsersPhoto(userId) {
      if (userId) {
        this.photo = await graph.getPhotoOfAUser(userId);
      }
    },
    getManagedDeviceGroupMemberships() {
      this.groupMembershipsAreLoading = true;
      if (this.deviceInfos.azureADDeviceId) {
        graph
          .getGroupMembershipsOfADevice(this.deviceInfos.azureADDeviceId, true)
          .then((response) => {
            this.groupMemberships = response;
          })
          .catch((error) => (this.groupMembershipsError = error));
      }
      this.groupMembershipsAreLoading = false;
    },
    async getManagedDeviceManagedApps() {
      this.managedAppsAreLoading = true;
      if (this.deviceInfos.id) {
        this.managedApps = await graph.getManagedDeviceManagedApps(
          this.deviceInfos.userId,
          this.deviceInfos.id
        );
        this.managedApps = this.managedApps.filter(
          (x) =>
            !(
              x.mobileAppIntent === "exclude" &&
              x.displayName.startsWith("UPD_")
            )
        );
      }
      this.managedAppsAreLoading = false;
    },
    triggerDeviceSync() {
      this.$store.dispatch("triggerDeviceActions", {
        devices: [this.deviceInfos],
        action: "sync",
      });
    },
    triggerDeviceReboot() {
      this.$store
        .dispatch("triggerDeviceActions", {
          devices: [this.deviceInfos],
          action: "reboot",
        })
        .then(() => (this.showRebootDialog = false));
    },
  },
  mounted() {
    this.getManagedDeviceInfos();
  },
};
</script>

<style lang="scss" scoped></style>
