import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import log from "./Services/log";

Vue.config.productionTip = false;

Vue.prototype.$config = {
  auth: {
    clientId: "dcf12e0f-ecba-4018-b5e0-cfa780c8928e",
    redirectUri: window.location.origin,
    authority:
      "https://login.microsoftonline.com/0df119c8-23ea-40d9-8dda-62d4a4db8dfb",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

Vue.mixin({
  methods: {
    formatDate: function (value) {
      var date = null;
      if (value) {
        date = new Date(value);
        date = date.toLocaleString();
        return String(date);
      }
    },
    formatSize: function (value) {
      var size = null;
      if (value) {
        size = Math.round(value / (1024 * 1024), 2) + " MB";
      } else {
        size = "Unknown";
      }
      return size;
    },
    formatCamelCaseToDisplayName(string) {
      return string.replace(/([A-Z])/g, " $1").trim();
    },
  },
});
log.TitleBar("Kallback");
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
