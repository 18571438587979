<template>
  <TSidePanel v-on:close="$emit('close')">
    <template v-slot:title> Move Users</template>

    <template v-slot:top-title> Selected Users ({{ items.length }})</template>

    <template v-slot:top-content>
      <v-list v-if="items.length" color="transparent" dense class="pt-0">
        <v-list-item v-for="device in items" :key="device.id" class="pl-0 ma-0">
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <span>
            {{ device.displayName }}
          </span>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:bottom-title> Select Destination</template>

    <template v-slot:bottom-content>
      <v-text-field
        v-model="search"
        class="mb-2"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search Role..."
        dense
        outlined
        :messages="searchMessage"
        :hide-details="!searchMessage"
        @input="getAvailableRoles()"
        clearable
      ></v-text-field>
      <v-data-table
        :items="availableRoles"
        :headers="headers"
        :show-select="!jobCreated"
        dense
        v-model="selectedRole"
        :loading="isLoading"
        :loader-height="2"
        single-select
        :items-per-page="-1"
        hide-default-footer
        no-data-text="No Roles available"
      >
        <template v-slot:item.displayName="{ item }">
          <span v-if="item.displayName">
            {{ item.displayName.split("_").slice(2).join(" | ") }}
          </span>
        </template>
      </v-data-table>
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="!jobCreated"
        color="primary"
        :disabled="!items.length"
        @click="moveObjectsToRole()"
      >
        <v-icon class="mr-2">mdi-transfer-right</v-icon>
        Move Users
      </v-btn>
      <v-btn v-else color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Action initiated
      </v-btn>
    </template>
  </TSidePanel>
</template>

<script>
import TSidePanel from "@/components/Templates/TSidePanel";
import mcmapi from "@/Services/mcm-api";

export default {
  components: {
    TSidePanel,
  },
  props: {
    group: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      jobCreated: false,
      headers: [{ text: "Name", value: "displayName" }],
      searchMessage: null,
      searchTimer: false,
      selectedRole: [],
      availableRoles: [],
      isLoading: false,
      search: null,
    };
  },
  methods: {
    getAvailableRoles(byEnter = false) {
      let timeout = 1200;
      clearTimeout(this.searchTimer);
      if (this.search && this.search.length < 3) {
        this.searchMessage = "Enter at least 3 characters";
      } else if (this.search) {
        if (byEnter) timeout = 10;
        this.searchMessage = "";
        this.searchTimer = setTimeout(async () => {
          this.selectedRole = [];
          this.isLoading = true;
          await mcmapi
            .GetRoles(this.search, this.group.scope, this.group.path[0])
            .then((response) => {
              this.availableRoles = response.values.filter(
                (x) => x.mcmType === "MCM_Users_BusinessRole"
              );
              if (response.count > 100)
                this.searchMessage =
                  "Too many results, please refine your search";
            })
            .catch((err) => console.warn(err))
            .finally(() => {
              this.isLoading = false;
            });
        }, timeout);
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    moveObjectsToRole() {
      this.$store.dispatch("moveObjectsToRole", {
        objects: this.items,
        currentGroup: this.group,
        targetGroup: this.selectedRole[0],
      });
      this.jobCreated = true;
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    },
  },
};
</script>
