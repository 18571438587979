<template>
  <v-card elevation="0" v-if="userInfos">
    <v-card-title v-if="embedded" class="pb-1 pl-6 pt-3">
      <v-row>
        <v-col cols="auto" class="pt-4">
          <v-avatar color="primary" size="55">
            <v-icon color="white" v-if="!photo" large> mdi-account </v-icon>
            <img v-else :src="photo" alt="photo" />
          </v-avatar>
        </v-col>
        <v-col cols="8" class="text-heading text-left pt-6">
          {{ userInfos.displayName }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                text
                :to="`/userdetails/${userInfos.id}`"
                ><v-icon color="grey">mdi-open-in-new</v-icon></v-btn
              >
            </template>
            <span>Full View</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-1">
      <v-row no-gutters class="py-2" justify="space-between">
        <v-col class="mb-0">
          <v-btn
            text
            class="font-weight-light text-capitalize"
            @click="getManagedUserInfos()"
          >
            <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-layout column style="height: calc(100vh - 204px)">
        <v-flex style="overflow-y: auto; overflow-x: hidden">
          <v-card v-if="!embedded" class="ma-4 mt-0" outlined>
            <v-card-text>
              <v-row>
                <v-col cols="auto" class="pt-4">
                  <v-avatar color="primary" size="55">
                    <v-icon color="white" v-if="!photo" large>
                      mdi-account
                    </v-icon>
                    <img v-else :src="photo" alt="photo" />
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="text-left pt-3">
                  <div class="text-h6 pt-1">
                    {{ userInfos.displayName }}
                    <p class="text-caption mb-0 mt-1">
                      {{ userInfos.userPrincipalName }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="ma-4 mt-0" outlined>
            <v-card-title>
              Details
              <v-btn
                text
                color="grey"
                class="text-capitalize"
                small
                @click="showAllDetails = !showAllDetails"
              >
                <v-icon>{{
                  showAllDetails ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Show {{ showAllDetails ? "Basic" : "Extended" }} User
                Details</v-btn
              >
            </v-card-title>
            <v-card-text v-if="!showAllDetails">
              <v-row
                v-for="prop in basicuserInfosProps"
                :key="prop.value"
                class="font-weight-bold"
              >
                <v-col :cols="embedded ? 4 : 2" class="pa-0 pl-4">
                  <span class="caption">{{ prop.text }}</span>
                </v-col>
                <v-col :cols="embedded ? 8 : 4" class="pa-0">
                  <span>{{
                    prop.transform
                      ? prop.transform(userInfos[prop.value])
                      : userInfos[prop.value]
                  }}</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="showAllDetails">
              <v-row
                v-for="(value, key) in userInfos"
                :key="key"
                class="font-weight-bold"
              >
                <v-col :cols="embedded ? 6 : 3" class="pa-0 pl-4">
                  <span class="caption">{{ key }}</span>
                </v-col>
                <v-col :cols="embedded ? 6 : 3" class="pa-0">
                  <span>{{ value }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            class="ma-4 mt-0"
            outlined
            :loading="primaryDevicesLoading"
            loader-height="2"
          >
            <v-card-title> Primary Devices </v-card-title>
            <v-card-text>
              <v-row class="font-weight-bold">
                <v-col
                  v-for="device in primaryDevices"
                  :key="device.id"
                  cols="6"
                  md="6"
                  lg="6"
                  xl="3"
                  sm="12"
                  class="pa-1"
                >
                  <v-btn
                    text
                    :to="`/devicedetails/${device.azureADDeviceId}`"
                    color="grey darken-3"
                  >
                    <v-icon class="mr-2" color="grey darken-1" size="28">{{
                      operatingSystemIcons[device.operatingSystem]
                    }}</v-icon>
                    <span>{{ device.deviceName }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>

  <v-card v-else flat>
    <v-card-text v-if="!userInfos && !isLoading" class="text-center mt-12"
      ><v-icon color="grey lighten-1" size="160"
        >mdi-emoticon-cry-outline</v-icon
      >
      <p class="mt-2 mb-0 text-overline">
        Sorry, the user you are looking for does not exist...
      </p>
    </v-card-text>
    <v-card-text v-if="!userInfos && isLoading" class="text-center mt-12">
      <v-skeleton-loader type="heading, table"></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import graph from "@/Services/graph";
import mcmapi from "@/Services/mcm-api";

export default {
  components: {},
  props: {
    userId: {
      type: String,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfos: null,
      showAllDetails: false,
      photo: null,
      isLoading: false,
      basicuserInfosProps: [
        { text: "UPN", value: "userPrincipalName" },
        { text: "Office Location", value: "officeLocation" },
        { text: "Job Title", value: "jobTitle" },
        { text: "Id", value: "id" },
      ],
      primaryDevices: null,
      primaryDevicesLoading: false,
      operatingSystemIcons: {
        Windows: "mdi-microsoft-windows",
        Android: "mdi-android",
        iOS: "mdi-apple-ios",
        macOS: "mdi-apple",
      },
    };
  },
  methods: {
    async getManagedUserInfos() {
      this.isLoading = true;
      this.userInfos = null;
      if (this.userId) {
        const result = await mcmapi.GetUserById(this.userId);
        if (result.values.length == 1) {
          this.userInfos = result.values[0];
          this.$store.commit(
            "setCurrentActiveObjectName",
            this.userInfos.displayName
          );
          await this.getUserPhoto();
          await this.getPrimaryDevices(this.userInfos.id);
        } else this.userInfos = null;
      }
      this.isLoading = false;
    },

    async getUserPhoto() {
      if (this.userInfos.id) {
        this.photo = await graph.getPhotoOfAUser(this.userInfos.id);
      }
    },
    async getPrimaryDevices(userId) {
      this.primaryDevicesLoading = true;
      this.primaryDevices = null;
      await graph
        .getPrimaryDevicesOfAUser(userId)
        .then((response) => {
          this.primaryDevices = response.value;
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.primaryDevicesLoading = false;
        });
    },
  },
  mounted() {
    this.getManagedUserInfos();
  },
};
</script>

<style lang="scss" scoped></style>
