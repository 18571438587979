<template>
  <div>
    <v-row no-gutters class="py-3" justify="space-between">
      <v-col>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'addUserToRole',
              group.type,
              permission
            )
          "
          text
          class="font-weight-light text-capitalize"
          @click="showAddUserDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Add Users</span>
        </v-btn>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'removeUserFromRole',
              group.type,
              permission
            )
          "
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showRemoveUserDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-minus</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Remove Users</span>
        </v-btn>
        <v-btn
          v-if="
            $store.getters.userHasPermission(
              'removeUserFromRole',
              group.type,
              permission
            )
          "
          text
          :disabled="!selectedItems.length"
          class="font-weight-light text-capitalize"
          @click="showMoveUserDialog = true"
        >
          <v-icon size="25" color="primary" class="mr-1"
            >mdi-transfer-right</v-icon
          >
          <span v-if="$vuetify.breakpoint.lgAndUp">Move</span>
        </v-btn>
        <v-btn
          text
          class="font-weight-light text-capitalize"
          @click="getUsers()"
          :disabled="isLoading"
        >
          <v-icon size="25" color="primary" class="mr-1">mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">Refresh</span>
        </v-btn>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" class="pr-3">
        <v-chip class="pa-5 border" color="primary" outlined
          ><v-icon class="mr-2">mdi-account</v-icon>
          {{ userCount + " " + (userCount == 1 ? "User" : "Users") }}</v-chip
        >
      </v-col>
      <v-col cols="3" md="3" xl="2">
        <v-responsive width="auto" min-width="120">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            hide-details
            outlined
            rounded
          ></v-text-field>
        </v-responsive>
      </v-col>
    </v-row>

    <v-layout column style="height: calc(100vh - 190px)">
      <v-flex style="overflow: auto">
        <v-data-table
          :items="items"
          :headers="headers"
          v-model="selectedItems"
          dense
          :show-select="group.type == 'BusinessRole'"
          :search="search"
          :loading="isLoading"
          :loader-height="2"
          loading-text="Loading Users..."
          :items-per-page="15"
          no-data-text="No Users in this role"
          sort-by="displayName"
          @current-items="getUserPhotos($event)"
        >
          <template v-slot:item.photo="{ item, index }">
            <v-avatar color="grey lighten-2" size="32" class="my-1">
              <img
                v-if="userPhotos[index]"
                :src="userPhotos[index]"
                alt="photo"
              />
              <v-icon color="grey" v-else> mdi-account </v-icon>
            </v-avatar>
          </template>
          <template v-slot:item.displayName="{ item }">
            <span class="pointer" @click="openUserDetailsDialog(item)">{{
              item.displayName
            }}</span>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      v-model="showAddUserDialog"
      absolute
      right
      width="500px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <AddUsers
        v-if="showAddUserDialog"
        :group="group"
        v-on:close="
          showAddUserDialog = false;
          getUsers();
        "
      ></AddUsers>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showRemoveUserDialog"
      absolute
      right
      width="500px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <RemoveUsers
        v-if="showRemoveUserDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="
          showRemoveUserDialog = false;
          getUsers();
        "
      ></RemoveUsers>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showMoveUserDialog"
      absolute
      right
      width="500px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <MoveUsers
        v-if="showMoveUserDialog"
        :group="group"
        :items="selectedItems"
        v-on:close="
          showMoveUserDialog = false;
          getUsers();
        "
      ></MoveUsers>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showUserDetailsDialog"
      absolute
      right
      width="800px"
      temporary
      :overlay-opacity="0"
      height="100%"
    >
      <UserDetails
        :userId="clickedUser.id"
        :key="clickedUser.id"
        embedded
        v-on:close="
          showUserDetailsDialog = false;
          userDetailsKey++;
        "
      ></UserDetails>
    </v-navigation-drawer>
  </div>
</template>

<script>
import graph from "@/Services/graph";
import mcmapi from "@/Services/mcm-api";
import AddUsers from "@/components/Users/AddUsers";
import RemoveUsers from "@/components/Users/RemoveUsers";
import MoveUsers from "@/components/Users/MoveUsers";
import UserDetails from "@/components/Users/UserDetails";

export default {
  components: {
    AddUsers,
    RemoveUsers,
    MoveUsers,
    UserDetails,
  },
  props: {
    group: {
      type: Object,
    },
    transitive: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
      default: "None",
    },
  },
  data() {
    return {
      items: [],
      userPhotos: [],
      headers: [
        { text: "", value: "photo", width: "35" },
        { text: "Name", value: "displayName" },
        { text: "UPN", value: "userPrincipalName" },
        { text: "Job Title", value: "jobTitle" },
      ],
      isLoading: false,
      search: null,
      selectedItems: [],
      clickedUser: { id: null },
      showAddUserDialog: false,
      showRemoveUserDialog: false,
      showMoveUserDialog: false,
      showUserDetailsDialog: false,
    };
  },
  methods: {
    async getUsers() {
      this.selectedItems = [];
      if (!this.group.id) return;
      this.items = [];
      this.isLoading = true;
      await mcmapi
        .GetMembers(this.group.id, this.transitive)
        .then((response) => {
          this.items = response.filter((x) => x["type"] == "user");
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
    openUserDetailsDialog(user) {
      this.clickedUser = user;
      this.showUserDetailsDialog = true;
    },
    async getUserPhotos(users) {
      this.userPhotos = [];
      if (users.length) {
        let requests = [];
        users.forEach((user) => {
          requests.push(graph.getPhotoOfAUser(user.id));
        });
        Promise.all(requests).then((responses) => {
          this.userPhotos = responses;
        });
      }
    },
  },
  computed: {
    userCount() {
      return this.items.length;
    },
  },
  mounted() {
    if (this.group) this.getUsers();
  },
};
</script>

<style></style>
